import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";
import Switch from 'components/Switch';
import Avatar from 'components/Avatar';
import CKEditor from 'ckeditor4-react';


import './style.css';

export default function AddCategory() {

    const history = useHistory();

    const [content, setContent] = useState('');
    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState('');
    const [usuarios, setUsuarios] = useState([])
    const [all, setAll] = useState(true);
    const [publication, setPublication] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });


    const addNotification = async () => {

        setIsLoading(true);

        try {
            await api.post('/admin/ondemand/notifications', { content, user, all, publication });
            setIsLoading(false);
            setMsg({ message: 'Notificação enviada com sucesso.', type: 'success' });
            setContent('');
            setAll(false);
            setPublication('')
            setUsuarios([])
            setUser(null)
            setUserName('')
            setAll(true)

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }

    useEffect(() => {

        const searchUsers = async () => {
            const response = await api.get(`/admin/clients?search=${userName}`);
            setUsuarios(response.data)
        }

        if (userName) {
            searchUsers()
        }

    }, [userName])

    const selectUser = (user) => {
        setUserName(user.name)
        setUser(user._id)
        setUsuarios([])
    }


    return (
        <Page name="ondemand">
            <h1>
                Nova Notificação
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>

            <div className="informacoes">

                <div className="container">
                    <Switch text="Enviar notificação para todos os usuários?" handleChange={setAll} handleChecked={all} />
                </div>


                {
                    all === false &&
                    <div className="container animated fadeIn">
                        <Fieldset label="Para:" type="text" placeholder="Comece digitar o nome do usuário." handleValue={userName} handleOnChange={(e) => setUserName(e.target.value)} />
                        {
                            usuarios.length > 0 && <>
                                <label>Selecione o usuário:</label>
                                <table className="uk-table uk-table-striped uk-table-small uk-table-middle tabelaDrop">
                                    <tbody>
                                        {
                                            usuarios.map((user, index) => (
                                                <tr key={index} onClick={() => selectUser(user)}>
                                                    <td width="30"><Avatar name={user?.name} image={user?.avatar} size={28} /></td>
                                                    <td>{user?.name}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                }


                <div className="container">
                    <h3>Dados da Notificação</h3>

                    <label>Conteúdo</label><CKEditor data={content} onChange={(e) => {
                        setContent(e.editor.getData());
                    }} config={{
                        height: 270
                    }} />
                    <br />
                    <Fieldset label="Data de publicação:" type="date" placeholder="Selecione a data" handleValue={publication} handleOnChange={(e) => setPublication(e.target.value)} />
                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Criar Notificação" isLoading={isLoading} handleOnClick={addNotification} handleType="submit" />
            </div>

        </Page>
    )
}
