import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function SaleProfiles() {
    const history = useHistory();
    const [saleProfiles, setSaleProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadSaleProfiles = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/sale-profiles`);
                setSaleProfiles(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadSaleProfiles();
    }, []);

    return (
        <Page name="sales" loading={isLoading}>
            <h1>Perfis de Venda
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/sales/sale-profiles/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Comissão</th>
                            <th>Nº de Vendas por Estrela</th>
                            <th>Nº de Estrelas p/ próximo nível</th>
                            <th>Próx. Nível</th>
                            <th width="130">Criado em:</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            saleProfiles.map((item, index) => (
                                <tr key={index}>
                                    <td><Link to={`/ondemand/sales/sale-profiles/edit/${item._id}`}>{item.name}</Link></td>
                                    <td>{item.commission}%</td>
                                    <td>{item.totalSalesForAStart}</td>
                                    <td>{item.numberStarsForNextLevel}</td>
                                    <td>{item.nextLevel?.name}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Link to={`/ondemand/sales/sale-profiles/edit/${item._id}`}><i className='bx bx-pencil' uk-tooltip="Editar perfil de venda" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


        </Page>
    )
}
