import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo_black.png';
import { getAuthUser, Logout } from 'services/auth';
import Avatar from 'components/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/Loading';

import './style.css';


export default function Page(props) {

    const { name, loading } = props;
    const [showMenu, setShowMenu] = useState(false)
    const [userLogged, setUserLogged] = useState({});
    const [subAudiobooks, setAudiobooks] = useState(false);
    const [subOndemand, setOndemand] = useState(false);
    const [subSales, setSales] = useState(false);
    const logged = useSelector(state => state.loginState.userLogged);

    const dispatch = useDispatch();
    const logout = () => {
        dispatch({ type: 'SET_LOGGED', isLogged: false })
        Logout();
    }

    useEffect(() => {
        const getUserLogged = async () => {
            const user = await getAuthUser()
            setUserLogged(user);
        }
        getUserLogged()
    }, [logged])


    return (
        <>
            <div className="header">

                <img src={logo} alt="Phast" />

                <div className="areaSubMenu">

                    <div className="areaProfile">

                        <div className="infoProfile">
                            <p>{userLogged?.name} <br /> <small>{userLogged?.email}</small></p>
                        </div>

                        <Avatar image={userLogged?.avatar} name={userLogged?.name} size={40} />

                    </div>

                    <div className="submenu animated fadeIn">
                        <Link to="/my-account"><li className={name === 'my-account' ? 'active' : ''}><span>Minha Conta</span><i className='bx bxs-user-detail'></i></li></Link>
                        <Link to="/update-password"><li className={name === 'update-password' ? 'active' : ''}><span>Alterar Senha</span><i className='bx bx-lock-open-alt'></i></li></Link>
                        <a href="/" onClick={logout}><li className={name === 'logout' ? 'active' : ''}><span>Logout</span><i className='bx bx-log-out' ></i></li></a>
                    </div>

                </div>

                <div className="btnMenu animated fadeIn" onClick={() => setShowMenu(!showMenu)}>
                    <i className='bx bx-menu'></i>
                </div>
            </div>

            {
                showMenu &&
                <div className="menuMobi animated fadeIn">
                    <div className="contentAside">
                        <ul>
                            {/* <Link to="/dashboard"><li className={name === 'dashboard' ? 'active' : ''}><i className='bx bxs-dashboard'></i> <span>Dashboard</span></li></Link> */}
                            <Link to="/audiobooks"><li className={name === 'audiobooks' ? 'active' : ''}><i className='bx bx-bx-book-alt'></i> <span>Audiobooks</span></li></Link>
                            <Link to="/chapters"><li className={name === 'chapters' ? 'active' : ''}><i className='bx bx-bx-book-alt'></i> <span>Capítulos</span></li></Link>
                            <Link to="/users"><li className={name === 'users' ? 'active' : ''}><i className='bx bx-group'></i> <span>Usuários</span></li></Link>
                            <Link to="/logs"><li className={name === 'logs' ? 'active' : ''}><i className='bx bx-task' ></i> <span>Logs</span></li></Link>
                            <Link to="/mailings"><li className={name === 'mailings' ? 'active' : ''}><i className='bx bx-file' ></i> <span>Mailings</span></li></Link>
                            <Link to="/profile"><li className={name === 'profile' ? 'active' : ''}><i className='bx bxs-user-detail'></i> <span>Meus Dados</span></li></Link>
                            <Link to="/logout"><li className={name === 'profile' ? 'active' : ''}><i className='bx bx-log-out'></i> <span>Logout</span></li></Link>
                        </ul>
                    </div>
                </div>
            }

            <div className="pagina">
                <div className="aside">
                    <div className="contentAside">
                        <ul>
                            <Link to="/dashboard"><li className={name === 'dashboard' ? 'active' : ''}><i className='bx bxs-dashboard'></i> <span>Dashboard</span></li></Link>

                            <li className={subOndemand || name === 'ondemand' ? 'active' : ''} onClick={() => setOndemand(!subOndemand)}><i className='bx bx-play-circle'></i><span>Ondemand</span></li>
                            {subOndemand &&
                                <ul className="dropMenu animated fadeIn">
                                    <Link to="/ondemand/categories"><li className={name === 'categories' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Categorias</span></li></Link>
                                    <Link to="/ondemand/levels"><li className={name === 'levels' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Níveis</span></li></Link>
                                    <Link to="/ondemand/courses"><li className={name === 'courses' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Cursos</span></li></Link>
                                    <Link to="/ondemand/exercises"><li className={name === 'exercises' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Exercícios</span></li></Link>
                                    <Link to="/ondemand/histories"><li className={name === 'histories' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Históricos</span></li></Link>
                                    <Link to="/ondemand/notifications"><li className={name === 'notifications' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Notificações</span></li></Link>
                                    <Link to="/ondemand/clients"><li className={name === 'clients' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Clientes</span></li></Link>
                                    <Link to="/ondemand/form-questions"><li className={name === 'form-questions' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Perguntas de Nivelamento</span></li></Link>
                                    <Link to="/ondemand/forms"><li className={name === 'forms' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Formulários</span></li></Link>
                                    <Link to="/ondemand/certificates"><li className={name === 'certificates' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Certificados</span></li></Link>
                                </ul>
                            }

                            <li className={subSales || name === 'sales' ? 'active' : ''} onClick={() => setSales(!subSales)}><i className='bx bx-store'></i><span>Vendas</span></li>
                            {
                                subSales &&
                                <ul className="dropMenu animated fadeIn">
                                    <Link to="/ondemand/plans"><li className={name === 'plans' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Planos</span></li></Link>
                                    <Link to="/ondemand/sales/sale-profiles"><li className={name === 'sale-profiles' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Perfis de Venda</span></li></Link>
                                    <Link to="/ondemand/sales/sellers"><li className={name === 'sellers' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Vendedores</span></li></Link>
                                    <Link to="/ondemand/sales/commissions"><li className={name === 'commissions' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Comissões</span></li></Link>
                                    <Link to="/ondemand/sales/history"><li className={name === 'history' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded' ></i> <span>Histórico de Vendas</span></li></Link>

                                </ul>
                            }

                            <li className={subAudiobooks || name === 'audiobooks' ? 'active' : ''} onClick={() => setAudiobooks(!subAudiobooks)}><i className='bx bx-book' ></i><span>Audiobooks</span></li>
                            {subAudiobooks &&
                                <ul className="dropMenu animated fadeIn">
                                    <Link to="/audiobooks"><li className={name === 'audiobooks' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i><span>Audiobooks</span></li></Link>
                                    <Link to="/chapters"><li className={name === 'chapters' ? 'active' : ''}><i className='bx bx-dots-horizontal-rounded'></i> <span>Capítulos</span></li></Link>
                                </ul>
                            }

                            {/* <Link to="/"><li className={name === 'subscriptions' ? 'active' : ''}><i className='bx bx-wallet'></i> <span>Assinaturas</span></li></Link> */}
                            <Link to="/users"><li className={name === 'users' ? 'active' : ''}><i className='bx bx-group'></i> <span>Usuários</span></li></Link>
                            <Link to="/questions"><li className={name === 'faq' ? 'active' : ''}><i className='bx bx-help-circle'></i> <span>Perguntas Frequentes</span></li></Link>
                            <Link to="/logs"><li className={name === 'logs' ? 'active' : ''}><i className='bx bx-task' ></i> <span>Logs</span></li></Link>
                            <Link to="/mailings"><li className={name === 'mailings' ? 'active' : ''}><i className='bx bx-file' ></i> <span>Mailings</span></li></Link>
                            <Link to="/settings"><li className={name === 'settings' ? 'active' : ''}><i className='bx bx-cog' ></i> <span>Configurações</span></li></Link>

                        </ul>
                    </div>
                </div>


                <div className="content animated fadeIn">
                    {loading ? <Loading /> : props.children}
                </div>

            </div>
        </>
    );
}