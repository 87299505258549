import React, { useState, useEffect } from 'react';
import CKEditor from 'ckeditor4-react';

function Information(props) {
   const { questions, results, infoExercise } = props;
   const [sentence, setSentence] = useState('')


   useEffect(() => {
      questions({ question: sentence })
      results({ response: true })
   }, [sentence, questions, results])


   useEffect(() => {
      if (infoExercise) {
         setSentence(infoExercise?.questions?.question)
      }
   }, [infoExercise])


   return <div className="container">
      <h3>Exercício de Informativo</h3>
      <CKEditor data={sentence} onChange={(e) => {
         setSentence(e.editor.getData());
      }} config={{
         height: 270
      }} />
   </div>;
}

export default Information;