import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import ButtonInlineSmall from 'components/ButtonInlineSmall';

import './style.css';


function Match(props) {


   const { questions, results } = props;

   const [question, setQuestion] = useState('')
   const [options, setOptions] = useState([])
   const [labelOption, setLabelOption] = useState('')
   const [word, setWord] = useState('')



   const removeOption = (o) => {
      options.splice(options.indexOf(o), 1)
      var opts = options;
      setOptions([...opts])
   }

   function addOptions() {
      if (!labelOption) return;
      setOptions([...options, { label: labelOption, correct: word }])
      setLabelOption('')
      setWord('')
   }



   useEffect(() => {
      const setValuesExercise = () => {
         let response = options.filter(item => item.correct)
         questions({ question, options })
         results({ response: response })
      }
      setValuesExercise();
   }, [question, options, questions, results])





   return (
      <div className="container cardFull">
         <h3>Exercício - Combine os Pares</h3>
         <div className='camposH'>
            <Fieldset label="Pergunta:" type="text" placeholder="Digite uma pergunta para o exercício." handleValue={question} handleOnChange={(e) => setQuestion(e.target.value)} />
         </div>


         <div className="camposH">
            <Fieldset label="Chamada:" type="text" placeholder="Escreva uma frase ou palavra" handleValue={labelOption} handleOnChange={(e) => setLabelOption(e.target.value)} />
            <Fieldset label="Palavra:" type="text" placeholder="Escreva uma palavra" handleValue={word} handleOnChange={(e) => setWord(e.target.value)} />
            <ButtonInlineSmall label="Adicionar" handleOnClick={() => addOptions()} />
         </div>

         <br />

         {
            options.length > 0 &&
            <div className="areaOptions">
               <label>Combinações Adicionadas:</label>
               <ul>
                  {
                     options.map((o, index) => (
                        <li key={index}>
                           <span>
                              <b>{o.label}</b>
                              {o.correct}
                           </span>

                           <span>
                              <i className='bx bx-trash iconRemove' onClick={() => removeOption(o)} style={{ marginLeft: 20 }}></i>
                           </span>
                        </li>
                     ))
                  }
               </ul>
            </div>
         }




      </div>
   );
}

export default Match;