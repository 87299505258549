import React from 'react';
import './style.css';

function ItemAudiobook(props) {

  const {color, size } = props;

  return <div className={`itemAudiobook ${color}`} style={{width: size, height: size}}>
    <i className='bx bx-music'></i>
  </div>

  
}

export default ItemAudiobook;