import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import ButtonInlineSmall from 'components/ButtonInlineSmall';
import { Switch } from 'react-switch-input';
import CKEditor from 'ckeditor4-react';

import './style.css';

function MultipleChoice(props) {


   const { questions, results, infoExercise } = props;

   const [question, setQuestion] = useState('')
   const [option, setOption] = useState({})
   const [options, setOptions] = useState([])
   const [labelOption, setLabelOption] = useState('')
   const [correctOption, setCorrectOption] = useState(false)

   function addOptions() {
      if (!option.label) return;

      // let o = options;
      // o.push(option)
      setOptions([...options, option])
      setLabelOption('')
      setCorrectOption(false)

   }

   useEffect(() => {
      setOption({ label: labelOption, correct: correctOption })
   }, [labelOption, correctOption])


   const removeOption = (o) => {
      options.splice(options.indexOf(o), 1)
      var opts = options;
      setOptions([...opts])
   }


   useEffect(() => {
      const setValuesExercise = () => {
         let response = options.filter(item => item.correct)
         questions({ question, options })
         results({ response: response })
      }
      setValuesExercise();
   }, [question, options, questions, results])

   useEffect(() => {

      if (infoExercise) {

      }

   }, [infoExercise])

   return (
      <div className="container cardFull">
         <h3>Exercício de Múltipla Escolha</h3>
         <label>Pergunta</label>
         {/* <Fieldset label="Pergunta:" type="text" placeholder="Digite uma pergunta para o exercício." handleValue={question} handleOnChange={(e) => setQuestion(e.target.value)} /> */}
         <CKEditor data={question} onChange={(e) => {
            setQuestion(e.editor.getData());
         }} config={{
            height: 120
         }} />

         <br />

         <div>
            <div className="camposH">
               <Fieldset label="Nova Opção:" type="text" placeholder="Adicione uma opção" handleValue={labelOption} handleOnChange={(e) => setLabelOption(e.target.value)} />
               <div>
                  <label>Correta?</label> <Switch name={'correct'} checked={correctOption} onChange={(e) => setCorrectOption(e.target.checked)} />
               </div>
            </div>
            <ButtonInlineSmall label="Adicionar" handleOnClick={() => addOptions()} />
         </div>
         <br />
         <label>Opções Adicionadas:</label>
         <div className="areaOptions">
            <ul>
               {
                  options.map((o, index) => (
                     <li key={index}>
                        <span>
                           {o.label}
                           {o.correct && <i className='bx bx-check-circle iconCorrect' ></i>}
                        </span>
                        <i className='bx bx-trash iconRemove' onClick={() => removeOption(o)}></i>
                     </li>
                  ))
               }
            </ul>
         </div>




      </div>
   );
}

export default MultipleChoice;