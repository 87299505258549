import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import FieldSelect from 'components/FieldSelect';
import api from 'services/api';


import './style.css';

export default function EditCategory() {

    const history = useHistory();
    const { _id } = useParams();

    

    const [name, setname] = useState('')
    const [commission, setcommission] = useState('')
    const [totalSalesForAStart, settotalSalesForAStart] = useState('')
    const [numberStarsForNextLevel, setnumberStarsForNextLevel] = useState('')
    const [nextLevel, setnextLevel] = useState('')
    const [saleProfiles, setSaleProfiles] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });


     
    useEffect(() => {
        
        const loadProfile = async() => {
            const response = await api.get(`admin/ondemand/sale-profiles/${_id}`);
            
            setname(response.data.name)
            setcommission(response.data.commission)
            settotalSalesForAStart(response.data.totalSalesForAStart)
            setnumberStarsForNextLevel(response.data.numberStarsForNextLevel)
            setnextLevel(response.data.nextLevel)
        }
        
        const loadSaleProfiles = async () => {
            const response = await api.get(`admin/ondemand/sale-profiles`);
            setSaleProfiles(response.data);
            loadProfile()
        }
        
        loadSaleProfiles();
    }, [_id]);



    const edit = async () => {

        setIsLoading(true);

        try {
            await api.put(`/admin/ondemand/sale-profiles/${_id}`, { 
                name,
                commission,
                totalSalesForAStart,
                numberStarsForNextLevel,
                nextLevel
             });
            setIsLoading(false);
            setMsg({ message: 'Perfil de venda alterado com sucesso.', type: 'success' });
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    const remove = async () => {
        var response = window.confirm("Tem certeza que quer remover esse perfil de venda?");
        if (response === true) {
            await api.delete(`/admin/ondemand/sale-profiles/${_id}`);
            history.goBack();
        }
    }


    return (
        <Page name="sales">
            <h1>
                Editar Perfil de Venda
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={() => remove()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o perfil." handleValue={name} handleOnChange={(e) => setname(e.target.value)} />
                    <Fieldset label="Comissão(%)" type="number" placeholder="Digite o valor" handleValue={commission} handleOnChange={(e) => setcommission(e.target.value)} />
                    <Fieldset label="Qtd. de vendas para uma estrela" type="number" placeholder="Informe o número de vendas" handleValue={totalSalesForAStart} handleOnChange={(e) => settotalSalesForAStart(e.target.value)} />
                    <Fieldset label="Qtd. de estrelas para o próximo nível" type="number" placeholder="Digite numberStarsForNextLevel" handleValue={numberStarsForNextLevel} handleOnChange={(e) => setnumberStarsForNextLevel(e.target.value)} />
                    <FieldSelect label="Próximo Nível" handleValue={nextLevel} handleOnChange={(e) => setnextLevel(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            saleProfiles.map((item, index) => (
                                <option key={index} value={item._id}>{item.name}</option>
                            ))
                        }
                    </FieldSelect>
                    
                </div>

            </div>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Editar Perfil" isLoading={isLoading} handleOnClick={edit} handleType="submit" />
            </div>

        </Page>
    )
}
