import React from 'react';
import './style.css';

function Badge(props) {

  const { label, type } = props;

  return <p className={`badge ${type}`}>{label}</p>

}

export default Badge;