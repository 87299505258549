import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import TagActive from 'components/TagActive';

import './style.css';

function CompleteSentence(props) {

   const { questions, results } = props;

   const [words, setWords] = useState([]);
   const [sentence, setSentence] = useState('');
   const [selectedWords, setSelectedWords] = useState([]);
   const [sentenceQuestion, setSentenceQuestion] = useState('');
   const [additional, setAdditional] = useState('');
   const [additionalWords, setAdditionalWords] = useState([]);

   useEffect(() => {
      let getWord = () => {
         setWords(sentence.split(' '))
      }
      getWord()
      setSentenceQuestion(sentence)

   }, [sentence, selectedWords])



   // Seleciona as palavras ocultas
   function selectWord(w) {
      if (!selectedWords.includes(w)) {
         selectedWords.push(w)

         var s;
         selectedWords.forEach(word => {
            s = sentenceQuestion.replace(word, '_____')
         });
         setSentenceQuestion(s)

      } else {
         selectedWords.splice(selectedWords.indexOf(w), 1)
      }
   }

   // Reseta as palavras selecionadas e a frase
   function resetWords() {
      setSentenceQuestion(sentence);
      setSelectedWords([])
      setAdditionalWords([])
   }

   function addAdditional() {
      setAdditionalWords([...additionalWords, additional])
      setAdditional('')
   }

   useEffect(() => {
      const setValuesExercise = () => {
         let options = [...selectedWords, ...additionalWords].sort(() => Math.random() - 0.5)
         questions({ question: sentenceQuestion, options })
         results({ response: selectedWords })
      }
      setValuesExercise();
   }, [selectedWords, additionalWords, questions, results, sentenceQuestion])


   return (
      <div className="container">
         <h3>Exercício Complete a Frase</h3>

         <Fieldset label="Frase" type="text" placeholder="Digite uma frase" handleValue={sentence} handleOnChange={(e) => setSentence(e.target.value)} />

         {words.length > 1 &&
            <div className="areaCampo">
               <label>Selecione as palavras ocultas: <b>{selectedWords.toString()}</b><br /><small>(Será respeitado a ordem de seleção)</small></label>

               <div className="areaWords">
                  {
                     words.map((w, index) => (
                        w !== '' && <TagActive key={index} label={w} handleOnClick={() => selectWord(w)} />
                     ))
                  }

                  <div className="ad">
                     {
                        additionalWords.map((aw, index) => (
                           aw !== '' && <TagActive key={index} label={aw} />
                        ))
                     }
                  </div>

                  <div className="additionals">
                     <Fieldset type="text" placeholder="Palavras adicionais" handleValue={additional} handleOnChange={(e) => setAdditional(e.target.value)} />
                     <span onClick={() => addAdditional()}><i className='bx bx-plus'></i></span>
                  </div>
               </div>
            </div>
         }
         {sentenceQuestion.length > 1 &&
            <div className="areaCampo">
               <label>Como será exibido para o aluno:</label>
               <div className="areaWords areaWordsGreen">
                  <TagActive label={sentenceQuestion} />
                  <span onClick={() => resetWords()}><i className='bx bx-refresh'></i></span>
               </div>
            </div>
         }

      </div>
   );
}

export default CompleteSentence;