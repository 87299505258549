import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import Pagination from "react-js-pagination";
import './style.css';

export default function Histories() {

    const [histories, setHistories] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);


    useEffect(() => {
        const loadHistories = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/histories?page=${page.toString()}`);
                setHistories(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);
            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os histories.', type: 'error'});
            }
        }
        loadHistories();
    }, [page])

    return (
        <Page name="histories" loading={isLoading}>
            <h1>Histórico</h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="40">Avatar</th>
                            <th>Usuário</th>
                            <th>Curso</th>
                            <th>Status</th>
                            <th width="130">Data</th>
                            <th width="120">Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            histories.map((history, index) => (
                                <tr key={index}>
                                    <td><Avatar name={history.user?.name} image={history.user?.avatar} size={28} /></td>
                                    <td>{history?.user?.name}</td>
                                    <td>{history?.course?.name}</td>
                                    <td>{history?.status}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(history.updatedAt), 'dd/M/Y')}</td>
                                    <td><i className='bx bx-time'></i>{format(parseISO(history.updatedAt), 'HH:mm')}hrs</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={Number(paginate.limit)}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />


        </Page>
    )
}
