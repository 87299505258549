import React, { useState, useEffect, useRef } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import FieldSelect from 'components/FieldSelect';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";

import './style.css';

export default function AddChapter() {

    const fileInput = useRef();
    const history = useHistory();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [audiobook, setAudiobook] = useState('');
    const [audiobooks, setAudiobooks] = useState([]);
    
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    const addChapter = async () => {

        setIsLoading(true);

        let audio = fileInput.current.files[0];

        const form = new FormData();
        form.append('name', name);
        form.append('description', description);
        form.append('audiobook', audiobook);
        form.append('audio', audio);
        
        try {

            await api({
                method: 'post',
                url: '/admin/chapters',
                data: form,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                },
            })

            setIsLoading(false);
            setMsg({ message: 'Chapter adicionado com sucesso.', type: 'success' });
            setName('');
            setDescription('');
            setAudiobook('');
            
            
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    useEffect(() => {
        const loadAudiobooks = async () => {
            const response = await api.get(`admin/audiobooks`);
            setAudiobooks(response.data);
        }
        loadAudiobooks();
    }, []);
 

    return (
        <Page name="assessment-types">
            <h1>
                Adicionar Capítulo
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <FieldSelect label="Audiobook" handleValue={audiobook} handleOnChange={(e) => setAudiobook(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            audiobooks.map((audioBook, index) => (
                                <option key={index} value={audioBook._id}>{audioBook.name}</option>
                            ))
                        }
                    </FieldSelect>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o plano." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o plano." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                    
                    <label>Audio:</label><br/>
                    <input type="file" ref={fileInput} />
                   

                </div>

            </div>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Capítulo" isLoading={isLoading} handleOnClick={addChapter} handleType="submit" />
            </div>

        </Page>
    )
}
