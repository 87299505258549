import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import TagActive from 'components/TagActive';

import './style.css';
import Avatar from 'components/Avatar';
import ButtonInlineSmall from 'components/ButtonInlineSmall';
import ReactAudioPlayer from 'react-audio-player';
import api from 'services/api';

function Dialog(props) {

   const { questions, results } = props;

   const [words, setWords] = useState([]);
   const [selectedWords, setSelectedWords] = useState([]);

   const [sentence, setSentence] = useState('');
   const [sentenceQuestion, setSentenceQuestion] = useState('');
   const [load, setLoad] = useState(false)
   const [person, setPerson] = useState('Pedro')
   const [messages, setMessages] = useState([]);
   const [additional, setAdditional] = useState('');
   const [additionalWords, setAdditionalWords] = useState([]);


   useEffect(() => {
      let getWord = () => {
         setWords(sentence.split(' '))
      }
      getWord()
      setSentenceQuestion(sentence)

   }, [sentence, selectedWords])



   // Seleciona as palavras ocultas
   function selectWord(w) {
      if (!selectedWords.includes(w)) {
         selectedWords.push(w)

         var s;
         selectedWords.forEach(word => {
            s = sentenceQuestion.replace(word, '_____')
         });
         setSentenceQuestion(s)

      } else {
         selectedWords.splice(selectedWords.indexOf(w), 1)
      }
   }

   // Reseta form
   function reset() {
      setSentenceQuestion('');
      setSelectedWords([])
      setAdditionalWords([])
      setSentence('')
      setLoad(false);
   }

   function addAdditional() {
      setAdditionalWords([...additionalWords, additional])
      setAdditional('')
   }

   useEffect(() => {


      const setValuesExercise = () => {
         if (!messages || messages.length === 0) return
         questions({ question: messages })
         results({ response: messages })
      }

      setValuesExercise();

   }, [questions, results, messages])


   async function addMessage() {

      const currentMessages = [...messages]

      setLoad(true);
      const audioResult = await api.post('admin/ondemand/google-api/text-to-speech', { text: sentence, voice: person === 'Pedro' ? 'NEUTRAL' : 'FEMALE' })
      let words = [...selectedWords, ...additionalWords].sort(() => Math.random() - 0.5)

      const msg = {
         sentenceResponse: sentence,
         sentenceQuestion,
         person,
         audio: audioResult.filename,
         words,
         correctwords: selectedWords
      }

      currentMessages.push(msg)

      setMessages(currentMessages)

      reset()
   }

   const removeMessage = (msg) => {
      messages.splice(messages.indexOf(msg), 1)
      let msgs = messages
      setMessages([...msgs])
   }

   return (
      <div className="container">
         <h3>Exercício - Diálogo</h3>


         <div className='camposH'>
            <Fieldset label="Frase" type="text" placeholder="Digite uma frase" handleValue={sentence} handleOnChange={(e) => setSentence(e.target.value)} />
            <div className='areaPerson'>
               <div><label>Personagem</label></div>
               <span>
                  <label><input type="radio" className='uk-radio' value="Pedro" checked={person === "Pedro"} onChange={(e) => setPerson(e.target.value)} /><Avatar image="https://i.pravatar.cc/150?img=8" size={28} /> Pedro</label>
                  <label><input type="radio" className='uk-radio' value="Anna" checked={person === "Anna"} onChange={(e) => setPerson(e.target.value)} /><Avatar image="https://i.pravatar.cc/150?img=5" size={28} /> Anna</label>
               </span>
            </div>

         </div>



         {words.length > 1 &&
            <div className="areaCampo">
               <label>Selecione as palavras ocultas: <b>{selectedWords.toString()}</b><br /><small>(Será respeitado a ordem de seleção)</small></label>

               <div className="areaWords">
                  {
                     words.map((w, index) => (
                        w !== '' && <TagActive key={index} label={w} handleOnClick={() => selectWord(w)} />
                     ))
                  }

                  <div className="ad">
                     {
                        additionalWords.map((aw, index) => (
                           aw !== '' && <TagActive key={index} label={aw} />
                        ))
                     }
                  </div>

                  <div className="additionals">
                     <Fieldset type="text" placeholder="Palavras adicionais" handleValue={additional} handleOnChange={(e) => setAdditional(e.target.value)} />
                     <span onClick={() => addAdditional()}><i className='bx bx-plus'></i></span>
                  </div>

               </div>
            </div>

         }
         {sentenceQuestion.length > 1 &&
            <div className="areaCampo">
               <label>Como será exibido para o aluno:</label>
               <div className="areaWords areaWordsGreen">
                  <TagActive label={sentenceQuestion} />
                  <span onClick={() => reset()}><i className='bx bx-refresh'></i></span>
               </div>
            </div>
         }


         <ButtonInlineSmall label="Adicionar" handleOnClick={() => addMessage()} isLoading={load} />


         <br />

         {
            messages.length > 0 &&
            <div className="areaOptions">
               <label>Mensagens Adicionadas:</label>
               <ul>
                  {
                     messages.map((msg, index) => (
                        <li key={index}>
                           <div style={{ width: '45%' }}>
                              <b>{msg.sentenceQuestion}</b><br />
                              <small>{msg.sentenceResponse}</small>
                           </div>

                           <span>
                              {
                                 msg.person === 'Pedro' ?
                                    <>
                                       <Avatar image="https://i.pravatar.cc/150?img=8" size={28} />
                                       <p>Pedro</p>
                                    </>
                                    :
                                    <>
                                       <Avatar image="https://i.pravatar.cc/150?img=5" size={28} />
                                       <p>Anna</p>
                                    </>
                              }
                           </span>

                           <span>
                              <ReactAudioPlayer
                                 src={`${api.baseUrl.replace('pt', '')}google/audios/${msg.audio}`}
                                 autoPlay={false}
                                 controls
                              />

                              <i className='bx bx-trash iconRemove' onClick={() => removeMessage(msg)} style={{ marginLeft: 20 }}></i>
                           </span>
                        </li>
                     ))
                  }
               </ul>
            </div>
         }-

      </div>
   );
}

export default Dialog;