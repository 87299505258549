import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import TagActive from 'components/TagActive';
import FieldSelect from 'components/FieldSelect';
import EmptyState from 'components/EmptyState';
import Fieldset from 'components/Fieldset';
import BtnInline from 'components/BtnInline';
import Msg from 'components/Msg';
import BtnIcon from 'components/BtnIcon';
import BtnTitle from 'components/BtnTitle';

export default function Commissions() {
    const [commissions, setCommissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [paids, setPaids] = useState('')
    const [startperiod, setstartperiod] = useState('')
    const [endperiod, setendperiod] = useState('')
    const [loadingFilter, setLoadingFilter] = useState(false)
    const [analytics, setanalytics] = useState([])

    const [msg, setMsg] = useState({ message: '', type: '' });

    useEffect(() => {
        const loadHistoryCommissions = async () => {
            setIsLoading(true);
            
            try {
                let response = await api.get(`/admin/commissions`);
                let resultAnalytics = await api.get(`/admin/commissions/analytics`);
                setCommissions(response.data);
                setanalytics(resultAnalytics.data)
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadHistoryCommissions();
    }, []);


    const filter = async () => {

        setLoadingFilter(true);

        let params = {}

        if(paids){
            params = {...params, paid: paids}
        }

        if(startperiod){
            params = {...params, startPeriod: startperiod}
        }

        if(endperiod){
            params = {...params, endPeriod: endperiod}
        }
            
        try {
            let response = await api.get(`/admin/commissions`, {params});
            let resultAnalytics = await api.get(`/admin/commissions/analytics`, {params});
            setanalytics(resultAnalytics.data)
            setCommissions(response.data);
            setLoadingFilter(false);
        } catch (error) {
            setLoadingFilter(false);
        }

    }

    const payment = async (_id) => {

        var response = window.confirm("Tem certeza que quer informar o pagamento de comissão?");

        if(!response) return;


        let params = {}


        if(_id){
            params = {...params, _id: _id}
        }else{
            if(startperiod){
                params = {...params, startPeriod: startperiod}
            }
    
            if(endperiod){
                params = {...params, endPeriod: endperiod}
            }
        }
    
            
        try {
            const result = await api.get(`/admin/commissions/payment`, {params});
            filter()
            setMsg({ message: result.msg, type: 'success' });
            
        } catch (error) {
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'warning' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }

    }


    return (
        <Page name="commissions" loading={isLoading}>
            <h1>Comissões</h1>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}
            
            <div className='camposH areaFilter'>
                <FieldSelect handleValue={paids} handleOnChange={(e) => setPaids(e.target.value)} label="Status">
                    <option value="">Selecionar</option>
                    <option value='true'>Comissões pagas</option>
                    <option value='false'>Comissões em aberto</option>
                </FieldSelect>

                <Fieldset type="date" handleValue={startperiod} handleOnChange={(e) => setstartperiod(e.target.value)} label="Início" />
                <Fieldset type="date" handleValue={endperiod} handleOnChange={(e) => setendperiod(e.target.value)} label="Término" />
                <BtnInline label="Filtrar" isLoading={loadingFilter} handleOnClick={filter}/>
            </div>

            {
                commissions.length === 0 ?

                <EmptyState title="Comissões" message="Não foram encontradas comissões." icon="wallet"/>

                :

               <>
                <div className='areaInfo'>
               {
                    analytics.map((item, index) => {
                        let valor = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.totalAmount)
                    return <div className="container cardDash" key={index}>
                        
                        <h3>Comissões {item._id ? 'Pagas' : 'em Aberto'} {
                            !item._id && <BtnTitle label="Informar pagamento" iconName="check" handleOnClick={() => payment()} />
                        }</h3>
                        <h1>{valor}</h1>
                        <small>Total de <b>{item.count}</b> comissões</small>
                            
                    
                    </div>
                    })
                }

               </div>

                <div className='container'>               

               
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Vendedor</th>
                            <th>Valor Venda</th>
                            <th>Perfil de venda</th>
                            <th>Valor Comissão</th>
                            <th>Quando</th>
                            <th width="25">Pagar</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            commissions.map((item, index) => {

                                let valorPlano = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.plan.amountPerPayment)
                                let valorComissao = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.amount)

                                return  <tr key={index}>
                                    <td><div className='cardProfile'>
                                            <Avatar name={item.seller.name} image={item.seller.avatar} size={28} />
                                            <span>
                                                <h4>{item.seller.name}</h4>
                                                <small>Cod: {item.seller.code}</small>
                                            </span>
                                        </div></td>
                                    <td><p className='success'><i className='bx bx-down-arrow-alt'></i>{valorPlano}</p></td>
                                    <td>{item.saleProfile.name}/{item.saleProfile.commission}%</td>
                                    <td><p className='error'><i className='bx bx-up-arrow-alt'></i>{valorComissao}</p></td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/MM/Y')}</td>
                                    <td>{item.paid === false ? <BtnIcon icon="check" handleOnClick={() => payment(item._id)}/> : "-"}</td>
                                    <td><TagActive label={item.paid ? 'Pago': 'Em aberto'} active={item.paid} /></td>
                                    
                                </tr>

                            })
                        }
                    </tbody>
                </table>

                

                </div>

               </>
            

            }

            
        </Page>
    )
}
