export default function getTypeExercise(type) {

    let typeFormat;

    switch (type) {

        case 'clickImage':
            typeFormat = 'Marcar Imagem'
            break

        case 'dialog':
            typeFormat = 'Diálogo'
            break

        case 'match':
            typeFormat = 'Combinação'
            break

        case 'cloud':
            typeFormat = 'Nuvem de Palavras'
            break

        case 'complete_frase':
            typeFormat = 'Complete a Frase'
            break

        case 'informativo':
            typeFormat = 'Informativo'
            break

        case 'multipla_escolha':
            typeFormat = 'Multipla Escolha'
            break

        case 'verdadeiro_falso':
            typeFormat = 'Verdadeiro ou Falso'
            break

        case 'compreensao':
            typeFormat = 'Compreensão'
            break

        case 'pronuncia':
            typeFormat = 'Pronúncia'
            break

        default:
            typeFormat = '-'
            break;
    }

    return typeFormat
}










