import React, { useState } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import FieldSelect from 'components/FieldSelect';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";

import './style.css';

export default function AddAudiobook() {

    const history = useHistory();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    const addAudiobook = async () => {

        setIsLoading(true);

        try {
            await api.post('/admin/audiobooks', { name, description, color });
            setIsLoading(false);
            setMsg({ message: 'Audiobook adicionado com sucesso.', type: 'success' });
            setName('');
            setDescription('');
            
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }
 

    return (
        <Page name="assessment-types">
            <h1>
                Adicionar Audiobook
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o plano." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o plano." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                    <FieldSelect label="Cor" handleValue={color} handleOnChange={(e) => setColor(e.target.value)}>
                        <option value="">Selecione</option>
                        <option value="blue">Azul</option>
                        <option value="green">Verde</option>
                        <option value="red">Vermelho</option>
                        <option value="yellow">Amarelo</option>
                        <option value="purple">Roxo</option>
                        <option value="pink">Rosa</option>                      
                    </FieldSelect>
                </div>

            </div>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Audiobook" isLoading={isLoading} handleOnClick={addAudiobook} handleType="submit" />
            </div>

        </Page>
    )
}
