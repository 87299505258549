import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';

import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";

import './style.css';
import BtnInline from 'components/BtnInline';

export default function AddLevel() {

    const history = useHistory();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [points, setPoints] = useState(0);
    const [image, setImage] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    const addLevel = async () => {

        setIsLoading(true);

        try {
            await api.post('/admin/ondemand/levels', { name, description, points, image });
            setIsLoading(false);
            setMsg({ message: 'Nível adicionada com sucesso.', type: 'success' });
            setName('');
            setDescription('');
            setImage('')
            setPoints(0)

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    return (
        <Page name="ondemand">
            <h1>
                Adicionar Nível
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="areaInfo">


                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome paro nível." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o nível." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                    <Fieldset label="Pontuação" type="number" placeholder="Digite uma pontuação para o nível." handleValue={points} handleOnChange={(e) => setPoints(e.target.value)} />
                </div>

                <div className='container areaImage'>
                    <h3 className='titulo'>Imagem Medalha <span>Defina uma imagem da medalha relacionada ao nível.</span></h3>
                    {image && <img src={image} alt={name} />}
                    <ImageUploading
                        acceptType={['jpg', 'jpeg', 'gif', 'png']}
                        resolutionType="ratio"
                        multiple={false}
                        onChange={(img) => {
                            setImage(img[0].data_url);
                        }}
                        dataURLKey="data_url"
                    >
                        {({ imageList, onImageUpload, onImageRemoveAll }) => (
                            <BtnInline label="Alterar imagem" handleOnClick={onImageUpload} />
                        )}
                    </ImageUploading>

                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Nível" isLoading={isLoading} handleOnClick={addLevel} handleType="submit" />
            </div>

        </Page>
    )
}
