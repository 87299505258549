import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import FieldSelect from 'components/FieldSelect';
import Button from 'components/Button';

import api from 'services/api';


import './style.css';

export default function EditChapter() {

    const fileInput = useRef();
    const history = useHistory();
    const { _id } = useParams();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [audiobook, setAudiobook] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [audiobooks, setAudiobooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    useEffect(() => {
        const loadChapter = async () => {
            const response = await api.get(`/admin/chapters/${_id}`);
            const chapter = response.data;
            setName(chapter.name);
            setDescription(chapter.description);
            setAudiobook(chapter.audiobook);
            setAudioUrl(chapter.audio);
        }
        loadChapter();

    }, [_id]);

    useEffect(() => {
        const loadAudiobooks = async () => {
            const response = await api.get(`admin/audiobooks`);
            setAudiobooks(response.data);
        }
        loadAudiobooks();
    }, []);



    const editChapter = async () => {

        setIsLoading(true);

        let audio = fileInput.current.files[0];

        const form = new FormData();
        form.append('_id', _id);
        form.append('name', name);
        form.append('description', description);
        form.append('audiobook', audiobook);
        form.append('audio', audio);

        try {

            await api({
                method: 'put',
                url: '/admin/chapters',
                data: form,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                },
            })

            setIsLoading(false);
            setMsg({ message: 'Chapters alterado com sucesso.', type: 'success' });
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    const removeChapter = async () => {
        var response = window.confirm("Tem certeza que quer remover esse capitulo?");
        if (response === true) {
            await api.delete(`/admin/chapters/${_id}`);
            history.goBack();
        }
    }


    return (
        <Page name="assessment-types">
            <h1>
                Editar Capítulo
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={() => removeChapter()} />
            </h1>


            <div className="informacoes">


                <div className="container">
                    <h3>Informações</h3>
                    <FieldSelect label="Audiobook" handleValue={audiobook} handleOnChange={(e) => setAudiobook(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            audiobooks.map((audioBook, index) => (
                                <option key={index} value={audioBook._id}>{audioBook.name}</option>
                            ))
                        }
                    </FieldSelect>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o plano." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o plano." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />



                    {/* <label>Audio: <a href={`https://unitedon-audiobooks.za9.com.br/uploads/audiobooks/${audiobook}/${audioUrl}`} target="_blank" rel="noopener noreferrer"><i className='bx bx-play-circle'></i></a></label><br/> */}

                    <ReactAudioPlayer
                        src={`https://unitedon-audiobooks.za9.com.br/uploads/audiobooks/${audiobook}/${audioUrl}`}
                        controls />

                    <input type="file" ref={fileInput} />
                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtn">
                <Button label="Editar Capítulo" isLoading={isLoading} handleOnClick={editChapter} handleType="submit" />
            </div>



        </Page>
    )
}
