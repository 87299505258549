import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import EmptyState from 'components/EmptyState';
import Fieldset from 'components/Fieldset';
import BtnInline from 'components/BtnInline';

export default function HistorySales() {
    const [sales, setSales] = useState([]);
    const [startperiod, setstartperiod] = useState('')
    const [endperiod, setendperiod] = useState('')
    const [loadingFilter, setLoadingFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const loadHistorySales = async () => {
            setIsLoading(true);
            
            try {
                const response = await api.get(`/admin/sales`);
                setSales(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadHistorySales();
    }, []);

    const filter = async () => {

        setLoadingFilter(true);

        let params = {}

        if(startperiod){
            params = {...params, startPeriod: startperiod}
        }

        if(endperiod){
            params = {...params, endPeriod: endperiod}
        }
            
        try {
            let response = await api.get(`/admin/sales`, {params});
            setSales(response.data);
            setLoadingFilter(false);
        } catch (error) {
            setLoadingFilter(false);
        }

    }

    return (
        <Page name="sales" loading={isLoading}>
            <h1>Histórico de Vendas</h1>

            <div className='camposH areaFilter'>
                <Fieldset type="date" handleValue={startperiod} handleOnChange={(e) => setstartperiod(e.target.value)} label="Início" />
                <Fieldset type="date" handleValue={endperiod} handleOnChange={(e) => setendperiod(e.target.value)} label="Término" />
                <BtnInline label="Filtrar" isLoading={loadingFilter} handleOnClick={filter}/>
            </div>

            <div className="container">
                {
                    sales.length === 0 ?
                    <EmptyState title="Vendas" message="Não foram encontradas vendas." icon="wallet"/>
                    :
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Vendedor</th>
                            <th>Plano</th>
                            <th>Quando</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sales.map((item, index) => {

                                let valor = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.plan.amountPerPayment)
                                let user = item.user[0];
                                let seller = item.seller[0];

                                return  <tr key={index}>
                                    <td>
                                        {user ? <div className='cardProfile'>
                                            <Avatar name={user.name} image={user.avatar} size={28} />
                                            <span>
                                                <h4>{user.name}</h4>
                                                <small>{user.email}</small>
                                            </span>
                                        </div> : '-'}
                                    </td>
                                    <td>
                                    {seller ? <div className='cardProfile'>
                                            <Avatar name={seller.name} image={seller.avatar} size={28} />
                                            <span>
                                                <h4>{seller.name}</h4>
                                                <small>{seller.code}</small>
                                            </span>
                                        </div> : '-'}
                                    </td>
                                    <td>
                                        <p>{item.plan.name}<br/><small>{valor}</small></p>
                                    </td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/MM/Y - H:m')}</td>
                                    
                                </tr>

                            })
                        }
                    </tbody>
                </table>
                }
            </div>
        </Page>
    )
}
