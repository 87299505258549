import React from 'react';

import Select from 'react-select'

import styles from './style.module.css';

export default function InputSelectSearch(props) {

    const { label, id, options, placeholder, handleStyle, handleValue, handleOnChange, required, obs } = props

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white', padding: '4px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? '#60C7EC'
                        : isFocused
                            ? '#C6D2E0'
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? '#fff' : '#676E75',
                cursor: isDisabled ? 'not-allowed' : 'pointer',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? '#60C7EC'
                            : '#51ADCF'
                        : undefined,
                },
            };
        },

    };


    return (
        <div className={`${styles.container} ${handleStyle}`}>
            <label>{label}</label>
            <Select
                instanceId={id || 'semId'}
                options={options}
                defaultValue={handleValue}
                className={styles.select}
                onChange={handleOnChange}
                placeholder={placeholder}
                required={required}
                styles={colourStyles}
            />
            {obs && <small handleStyle={styles.obs}>{obs}</small>}
        </div>
    )
} 