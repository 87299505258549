import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Páginas
import Dashboard from './../pages/Dashboard';

import Logs from './../pages/Logs';

import MyAccount from './../pages/MyAccount';
import UpdatePassword from './../pages/UpdatePassword';

import Users from './../pages/Users/ListUsers';
import AddUser from './../pages/Users/AddUser';
import EditUser from './../pages/Users/EditUser';

import Audiobooks from './../pages/Audiobooks/ListAudiobook';
import AddAudiobook from './../pages/Audiobooks/AddAudiobook';
import EditAudiobook from './../pages/Audiobooks/EditAudiobook';

import Chapters from './../pages/Chapters/ListChapters';
import AddChapter from './../pages/Chapters/AddChapter';
import EditChapter from './../pages/Chapters/EditChapter';


import Categories from './../pages/Ondemand/Categories/ListCategories';
import AddCategory from './../pages/Ondemand/Categories/AddCategory';
import EditCategory from './../pages/Ondemand/Categories/EditCategory';

import Forms from './../pages/Ondemand/Forms/ListForms';
import AddForm from './../pages/Ondemand/Forms/AddForm';
import EditForm from './../pages/Ondemand/Forms/EditForm';

import FormQuestions from './../pages/Ondemand/FormQuestions/ListFormQuestions';
import AddFormQuestion from './../pages/Ondemand/FormQuestions/AddFormQuestion';
import EditFormQuestion from './../pages/Ondemand/FormQuestions/EditFormQuestion';

import Levels from './../pages/Ondemand/Levels/ListLevels';
import AddLevel from './../pages/Ondemand/Levels/AddLevel';
import EditLevel from './../pages/Ondemand/Levels/EditLevel';


import Certificates from './../pages/Ondemand/Certificates/ListCertificates';
import AddCertificate from './../pages/Ondemand/Certificates/AddCertificate';

// Sales
import SaleProfiles from './../pages/Ondemand/Sales/SaleProfiles/ListSaleProfiles';
import AddSaleProfile from './../pages/Ondemand/Sales/SaleProfiles/AddSaleProfile';
import EditSaleProfile from './../pages/Ondemand/Sales/SaleProfiles/EditSaleProfile';

import Sellers from './../pages/Ondemand/Sales/Sellers/ListSellers';
import AddSeller from './../pages/Ondemand/Sales/Sellers/AddSeller';
import EditSeller from './../pages/Ondemand/Sales/Sellers/EditSeller';

import HistorySales from './../pages/Ondemand/Sales/History/ListHistory';

import Commissions from './../pages/Ondemand/Sales/Commissions/ListCommissions';


import Courses from './../pages/Ondemand/Courses/ListCourses';
import AddCourse from './../pages/Ondemand/Courses/AddCourse';
import EditCourse from './../pages/Ondemand/Courses/EditCourse';

import Exercises from './../pages/Ondemand/Exercises/ListExercises';
import AddExercise from './../pages/Ondemand/Exercises/AddExercise';
import EditExercise from './../pages/Ondemand/Exercises/EditExercise';

import Clients from './../pages/Ondemand/Clients/ListClients';
import AddClient from './../pages/Ondemand/Clients/AddClient';

import Histories from './../pages/Ondemand/Histories';

import Questions from './../pages/Questions/ListQuestions';
import AddQuestion from './../pages/Questions/AddQuestion';
import EditQuestion from './../pages/Questions/EditQuestion';

import Settings from '../pages/Settings';

import Mailings from '../pages/Mailings';

import Notifications from '../pages/Ondemand/Notifications/ListNotifications';
import AddNotification from '../pages/Ondemand/Notifications/AddNotification';
import EditNotification from '../pages/Ondemand/Notifications/EditNotification';


import Plans from '../pages/Ondemand/Plans/ListPlans';
import AddPlan from '../pages/Ondemand/Plans/AddPlan';

export default function Private() {
    return (
        <Router>
            <Switch>
                {/* <Route exact path='/dashboard' component={Dashboard} /> */}

                <Route exact path='/logs' component={Logs} />

                <Route exact path='/my-account' component={MyAccount} />
                <Route exact path='/update-password' component={UpdatePassword} />

                <Route exact path='/users' component={Users} />
                <Route exact path='/users/add' component={AddUser} />
                <Route exact path='/users/edit/:_id' component={EditUser} />

                <Route exact path='/audiobooks' component={Audiobooks} />
                <Route exact path='/audiobooks/add' component={AddAudiobook} />
                <Route exact path='/audiobooks/edit/:_id' component={EditAudiobook} />

                <Route exact path='/chapters' component={Chapters} />
                <Route exact path='/chapters/add' component={AddChapter} />
                <Route exact path='/chapters/edit/:_id' component={EditChapter} />

                <Route exact path='/ondemand/categories' component={Categories} />
                <Route exact path='/ondemand/categories/add' component={AddCategory} />
                <Route exact path='/ondemand/categories/edit/:_id' component={EditCategory} />


                <Route exact path='/ondemand/forms' component={Forms} />
                <Route exact path='/ondemand/forms/add' component={AddForm} />
                <Route exact path='/ondemand/forms/edit/:_id' component={EditForm} />

                <Route exact path='/ondemand/form-questions' component={FormQuestions} />
                <Route exact path='/ondemand/form-questions/add' component={AddFormQuestion} />
                <Route exact path='/ondemand/form-questions/edit/:_id' component={EditFormQuestion} />

                <Route exact path='/ondemand/certificates' component={Certificates} />
                <Route exact path='/ondemand/certificates/add' component={AddCertificate} />

                <Route exact path='/ondemand/levels' component={Levels} />
                <Route exact path='/ondemand/levels/add' component={AddLevel} />
                <Route exact path='/ondemand/levels/edit/:_id' component={EditLevel} />

                <Route exact path='/ondemand/courses' component={Courses} />
                <Route exact path='/ondemand/courses/add' component={AddCourse} />
                <Route exact path='/ondemand/courses/edit/:_id' component={EditCourse} />

                <Route exact path='/ondemand/exercises' component={Exercises} />
                <Route exact path='/ondemand/exercises/add' component={AddExercise} />
                <Route exact path='/ondemand/exercises/edit/:_id' component={EditExercise} />


                <Route exact path='/ondemand/histories' component={Histories} />

                <Route exact path='/ondemand/clients' component={Clients} />
                <Route exact path='/ondemand/clients/add' component={AddClient} />

                <Route exact path='/ondemand/plans' component={Plans} />
                <Route exact path='/ondemand/plans/add' component={AddPlan} />

                <Route exact path='/questions' component={Questions} />
                <Route exact path='/questions/add' component={AddQuestion} />
                <Route exact path='/questions/edit/:_id' component={EditQuestion} />

                <Route exact path='/settings' component={Settings} />

                <Route exact path='/mailings' component={Mailings} />

                <Route exact path='/ondemand/notifications' component={Notifications} />
                <Route exact path='/ondemand/notifications/add' component={AddNotification} />
                <Route exact path='/ondemand/notifications/edit/:_id' component={EditNotification} />


                <Route exact path='/ondemand/sales/sale-profiles' component={SaleProfiles} />
                <Route exact path='/ondemand/sales/sale-profiles/add' component={AddSaleProfile} />
                <Route exact path='/ondemand/sales/sale-profiles/edit/:_id' component={EditSaleProfile} />

                <Route exact path='/ondemand/sales/sellers' component={Sellers} />
                <Route exact path='/ondemand/sales/sellers/add' component={AddSeller} />
                <Route exact path='/ondemand/sales/sellers/edit/:_id' component={EditSeller} />

                <Route exact path='/ondemand/sales/history' component={HistorySales} />

                <Route exact path='/ondemand/sales/commissions' component={Commissions} />

                <Route path='/*' component={Dashboard} />

            </Switch>
        </Router>
    )
}