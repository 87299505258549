import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import ImageUploading from 'react-images-uploading';

import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';

import api from 'services/api';


import './style.css';

import BtnInline from 'components/BtnInline';

export default function EditLevel() {

    const history = useHistory();
    const { _id } = useParams();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [points, setPoints] = useState(0);
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    useEffect(() => {
        const loadLevel = async () => {
            const response = await api.get(`/admin/ondemand/levels/${_id}`);
            const level = response.data;
            setName(level.name);
            setDescription(level.description);
            setPoints(level.points);
            setImage(level.image);
        }
        loadLevel();

    }, [_id])



    const editLevel = async () => {

        setIsLoading(true);

        try {
            await api.put('/admin/ondemand/levels', { _id, name, description, points, image });
            setIsLoading(false);
            setMsg({ message: 'Nível alterada com sucesso.', type: 'success' });
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    const removeLevel = async () => {
        var response = window.confirm("Tem certeza que quer remover essa nível?");
        if (response === true) {
            await api.delete(`/admin/ondemand/levels/${_id}`);
            history.goBack();
        }
    }


    return (
        <Page name="ondemand">
            <h1>
                Editar Nível
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={() => removeLevel()} />
            </h1>

            <div className="areaInfo">

                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome paro nível." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o nível." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                    <Fieldset label="Pontuação" type="number" placeholder="Digite uma pontuação para o nível." handleValue={points} handleOnChange={(e) => setPoints(e.target.value)} />
                </div>

                <div className='container areaImage'>
                    <h3 className='titulo'>Imagem Medalha <span>Defina uma imagem da medalha relacionada ao nível.</span></h3>
                    <img src={image} alt={name} />
                    <ImageUploading
                        acceptType={['jpg', 'jpeg', 'gif', 'png']}
                        resolutionType="ratio"
                        multiple={false}
                        onChange={(img) => {
                            setImage(img[0].data_url);
                        }}
                        dataURLKey="data_url"
                    >
                        {({ imageList, onImageUpload, onImageRemoveAll }) => (
                            <BtnInline label="Alterar imagem" handleOnClick={onImageUpload} />
                        )}
                    </ImageUploading>

                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtn">
                <Button label="Editar Nível" isLoading={isLoading} handleOnClick={editLevel} handleType="submit" />
            </div>

        </Page>
    )
}
