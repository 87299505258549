import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';

import api from 'services/api';


import './style.css';

export default function EditCategory() {

    const history = useHistory();
    const { _id } = useParams();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });


    useEffect(() => {
        const loadCategory = async () => {
            const response = await api.get(`/admin/ondemand/categories/${_id}`);
            const category = response.data;
            setName(category.name);
            setDescription(category.description);
        }
        loadCategory();

    }, [_id])



    const editCategory = async () => {

        setIsLoading(true);

        try {
            await api.put('/admin/ondemand/categories', { _id, name, description });
            setIsLoading(false);
            setMsg({ message: 'Categoria alterada com sucesso.', type: 'success' });
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    const removeCategory = async () => {
        var response = window.confirm("Tem certeza que quer remover essa categoria?");
        if (response === true) {
            await api.delete(`/admin/ondemand/categories/${_id}`);
            history.goBack();
        }
    }


    return (
        <Page name="ondemand">
            <h1>
                Editar Categoria
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={() => removeCategory()} />
            </h1>


            <div className="informacoes">


                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para categoria." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para a categoria." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtn">
                <Button label="Editar Categoria" isLoading={isLoading} handleOnClick={editCategory} handleType="submit" />
            </div>



        </Page>
    )
}
