import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory, useParams } from "react-router-dom";

import './style.css';
import InputSelectSearch from 'components/InputSelectSearch';
import BtnInline from 'components/BtnInline';
import Badge from 'components/Badge';

export default function AddForm() {

    const history = useHistory();
    const { _id } = useParams();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [questions, setQuestions] = useState([])
    const [questionsSelecteds, setQuestionsSelecteds] = useState([])
    const [question, setQuestion] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });

    // Load courses
    useEffect(() => {

        let q = []

        const loadForm = async () => {
            if (q.length === 0) return
            const response = await api.get(`/admin/ondemand/forms/${_id}`);
            const form = response.data;
            setName(form.name);
            setDescription(form.description);

            const loadQuestions = form.questions.map((item) => q.find((i) => i.value === item))

            setQuestionsSelecteds(loadQuestions)

        }


        const load = async () => {
            const response = await api.get(`admin/ondemand/form-questions`);
            const questionsData = response.data.map((item) => {
                return {
                    value: item._id,
                    label: item.question,
                    points: item.points
                }
            })
            setQuestions(questionsData)
            q = questionsData
            loadForm();
        }
        load();

    }, [_id]);



    const addForm = async () => {

        setIsLoading(true);

        const questionsIds = questionsSelecteds.map((item) => item.value)

        const points = questionsSelecteds.reduce((acumulador, item) => acumulador + item.points, 0)


        try {
            await api.put('/admin/ondemand/forms', { _id, name, description, questions: questionsIds, points });
            setIsLoading(false);
            setMsg({ message: 'Formulário salvo com sucesso.', type: 'success' });


        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }

    const addQuestion = () => {
        const selecteds = [...questionsSelecteds]
        const questionSelected = questions.find((item) => item.value === question)
        selecteds.push(questionSelected)
        setQuestionsSelecteds(selecteds)
    }

    const removeQuestion = (item) => {
        questionsSelecteds.splice(questionsSelecteds.indexOf(item), 1)
        var opts = questionsSelecteds;
        setQuestionsSelecteds([...opts])
    }


    const removeForm = async () => {
        var response = window.confirm("Tem certeza que quer remover esse formulário?");
        if (response === true) {
            await api.delete(`/admin/ondemand/forms/${_id}`);
            history.goBack();
        }
    }

    return (
        <Page name="ondemand">
            <h1>
                Editar Formulário
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={() => removeForm()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o formulário." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o formulário." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />

                </div>

            </div>

            <div className="informacoes">

                <div className="container">



                    <h3>Perguntas</h3>
                    <InputSelectSearch id="question" options={questions} handleValue={questions.find((item) => item.value === question)} handleOnChange={(item) => setQuestion(item.value)} placeholder="Adicione uma pergunta" />
                    <BtnInline label="Adicionar Pergunta" handleOnClick={() => addQuestion()} />

                    {
                        questionsSelecteds.length > 0 &&
                        <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                            <thead>
                                <tr>
                                    <th>Pergunta</th>
                                    <th width="30">Pontos</th>
                                    <th width="30">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    questionsSelecteds.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.label}</td>

                                            <td>
                                                <Badge label={item.points} />
                                            </td>
                                            <td>
                                                <i className='bx bx-trash iconRemove' onClick={() => removeQuestion(item)}></i>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }

                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Salvar Formulário" isLoading={isLoading} handleOnClick={addForm} handleType="submit" />
            </div>

        </Page>
    )
}
