import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import ImageUploading from 'react-images-uploading';
import ButtonInlineSmall from 'components/ButtonInlineSmall';
import styles from './style.module.css';
import ReactAudioPlayer from 'react-audio-player';
import api from 'services/api';

import Button from 'components/Button';

function ClickImage(props) {

   const { questions, results, infoExercise } = props;
   const [question, setQuestion] = useState('');
   const [imageQuestion, setImageQuestion] = useState('');
   const [marks, setMarks] = useState([])




   useEffect(() => {
      questions({ question, marks, image: imageQuestion })
      results({ response: marks.map((mark) => mark.label) })

   }, [questions, results, question, marks, imageQuestion])


   useEffect(() => {

      if (infoExercise) {

      }

   }, [infoExercise])


   function marking(e) {

      const x = Math.round(((e.nativeEvent.layerX - 12) * 100) / e.target.offsetWidth)
      const y = Math.round(((e.nativeEvent.layerY - 12) * 100) / e.target.offsetHeight)

      const newMark = { x: x < 0 ? 0 : x, y: y < 0 ? 0 : y }
      setMarks([...marks, newMark])

   }

   const removeMark = (index) => {
      const currentMarks = [...marks]
      currentMarks.splice(index, 1)
      setMarks(currentMarks)
   }

   const generateAudio = async (mark) => {
      const newMarks = [...marks]
      const audio = await api.post('admin/ondemand/google-api/text-to-speech', { text: mark?.label })
      mark.audio = audio?.filename
      newMarks[newMarks.indexOf(mark)] = mark
      setMarks(newMarks)

   }

   useEffect(() => {
      console.log(marks)
   }, [marks])


   return (
      <div className="container cardFull">
         <h3>Exercício de Imagem</h3>

         <Fieldset label="Pergunta" type="text" placeholder="Digite uma pergunta" handleValue={question} handleOnChange={(e) => setQuestion(e.target.value)} />

         <div className={styles.areaImagem}>
            <div className={styles.inputImagem}>


               <ImageUploading
                  acceptType={['jpg', 'jpeg', 'gif', 'png']}
                  resolutionType="ratio"
                  multiple={false}
                  onChange={async (image) => {
                     setImageQuestion(image[0].data_url);
                  }}
                  dataURLKey="data_url">
                  {({ imageList, onImageUpload, onImageRemoveAll }) => (
                     <ButtonInlineSmall label="Selecione uma imagem" handleOnClick={onImageUpload} className={styles.btn} />
                  )}
               </ImageUploading>
               {
                  imageQuestion &&

                  <div onClick={marking} className={styles.imagem}>
                     <img src={imageQuestion} alt="Imagem de marcação" />
                     {
                        marks.map((mark, index) => <div key={index} className={`${styles.mark} animated bounceIn`} style={{ left: `${mark.x}%`, top: `${mark.y}%` }} >
                           <span>{index + 1}</span>
                        </div>)
                     }

                  </div>
               }

               <small><b>Atenção:</b> A imagem deve ter dimensões de no máximo <b>1920x1080px</b> e o tamnaho de até <b>1.5mb</b></small>

            </div>

            <div className={styles.areaMarks}>
               {
                  marks.map((mark, index) => (
                     <div className={styles.areaWord}>
                        <div key={index} className={styles.campoMark}>
                           <Fieldset label={`Marcação - ${index + 1}`} type="text" placeholder="Digite a palavra referente a marcação" handleValue={mark.label} handleOnChange={(e) => mark.label = e.target.value} />
                           <Button label="Gerar audio" handleOnClick={() => generateAudio(mark)} handleStyle={styles.btnAudio} />
                           <span onClick={() => removeMark(index)}><i className='bx bx-trash'></i></span>
                        </div>
                        {mark?.audio && <ReactAudioPlayer
                           className={styles.player}
                           src={`${api.baseUrl.replace('pt', '')}google/audios/${mark.audio}`}
                           autoPlay={false}
                           controls
                        />}

                     </div>
                  ))
               }
            </div>

         </div>


      </div>
   );
}

export default ClickImage;