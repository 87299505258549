import React from 'react';
import style from './style.module.css';

function Modal(props) {

  const { handleOnClose } = props;

  return (
    <div className={style.over}>
      <div className={style.container}>
        <div className={style.btnClose} onClick={handleOnClose}><i className='bx bx-x'></i></div>
        {props.children}
      </div>
    </div>
  )
}

export default Modal;