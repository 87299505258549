import React, { useState, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import FieldSelect from 'components/FieldSelect';

import ButtonInlineSmall from 'components/ButtonInlineSmall';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";



import './style.css';
import InputTags from 'components/InputTags';

export default function AddCourse() {

    const history = useHistory();

    const [name, setName] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [level, setLevel] = useState('');
    const [levels, setLevels] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });

    const [tags, setTags] = useState([])



    const addCourse = async () => {

        setIsLoading(true);

        const keyWords = tags.length > 0 ? tags.map((item) => item.text) : []

        try {
            await api.post('/admin/ondemand/courses', { name, excerpt, description, image, category, keyWords, level });
            setIsLoading(false);
            setMsg({ message: 'Curso adicionado com sucesso.', type: 'success' });
            setName('');
            setExcerpt('');
            setDescription('');
            setImage('');
            setCategory('');

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }

            console.log(error)

            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    useEffect(() => {
        const loadCategories = async () => {
            const response = await api.get(`admin/ondemand/categories`);
            setCategories(response.data);
        }
        loadCategories();

        const loadLevels = async () => {
            const response = await api.get(`admin/ondemand/levels`);
            setLevels(response.data);
        }
        loadLevels();
    }, []);




    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };


    return (
        <Page name="ondemand">
            <h1>
                Adicionar Curso
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="areaInfo">

                <div className="container cardFull">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o curso." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Resumo" type="text" placeholder="Digite um breve resumo do curso." multiline={true} handleValue={excerpt} handleOnChange={(e) => setExcerpt(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o curso." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                    <FieldSelect label="Categoria" handleValue={category} handleOnChange={(e) => setCategory(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            categories.map((item, index) => (
                                <option key={index} value={item._id}>{item.name}</option>
                            ))
                        }
                    </FieldSelect>
                    <FieldSelect label="Nível" handleValue={level} handleOnChange={(e) => setLevel(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            levels.map((item, index) => (
                                <option key={index} value={item._id}>{item.name} - {item.points} pts</option>
                            ))
                        }
                    </FieldSelect>

                    <InputTags tags={tags} label="Palavras-Chave" handleDelete={handleDelete} handleAddition={handleAddition} />

                </div>

                <div className="areaImageCourse">
                    <div className="container">
                        {
                            image ? <div style={{ backgroundImage: `url(${image})` }} className="thumbCourse"></div> : <i className='bx bx-image'></i>
                        }
                    </div>
                    <br />
                    <ImageUploading
                        acceptType={['jpg', 'jpeg', 'png']}
                        resolutionType="ratio"
                        multiple={false}
                        onChange={(image) => {
                            setImage(image[0].data_url);
                        }}
                        dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemoveAll }) => (
                            <ButtonInlineSmall label="Adicionar Imagem" handleOnClick={onImageUpload} />
                        )}
                    </ImageUploading>
                </div>





            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Curso" isLoading={isLoading} handleOnClick={addCourse} handleType="submit" />
            </div>

        </Page>
    )
}
