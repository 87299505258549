import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import Avatar from 'components/Avatar';

export default function Certificates() {
    const history = useHistory();
    const [certificates, setCertificates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const loadCertificates = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/admin/ondemand/certificates`);
            setCertificates(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadCertificates();
    }, []);

    const remove = async (_id) => {
        var response = window.confirm("Tem certeza que quer remover esse certificado?");
        setIsLoading(true)
        if (response === true) {
            await api.delete(`/admin/ondemand/certificates/${_id}`);
            loadCertificates()
        }
    }

    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Certificados
                <BtnTitle label="Gerar novo certificado" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/certificates/add')} />
            </h1>

            <div className="container">
                {
                    certificates.length > 0 &&
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th width="50">#</th>
                                <th width="40">#</th>
                                <th width="200">Aluno</th>
                                <th>Certificado</th>
                                <th width="130">Criado em:</th>
                                <th width="20">#</th>
                                <th width="20">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                certificates.map((certificate, index) => (
                                    <tr key={index}>
                                        <td><img src={certificate?.level?.image} alt="" /></td>
                                        <td><Avatar name={certificate?.user?.name} image={certificate?.user?.avatar} size={28} /></td>
                                        <td>{certificate.user.name}</td>
                                        <td>{certificate.level.name}</td>
                                        <td><i className='bx bx-calendar'></i>{format(parseISO(certificate.createdAt), 'dd/M/Y')}</td>
                                        <td align="center"><a href={`https://api.unitedondemand.com.br/certificates/${certificate.image}`} target='_blank' rel="noopener noreferrer"><i className='bx bx-download' uk-tooltip=" Baixar certificado" style={{ fontSize: '17px', display: 'flex' }} ></i></a></td>
                                        <td align="center"><span onClick={() => remove(certificate._id)} className="iconRemove"><i className='bx bx-trash' uk-tooltip="Remover Certificado" ></i></span></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }

            </div >


        </Page >
    )
}
