import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import ButtonInlineSmall from 'components/ButtonInlineSmall';
import { Switch } from 'react-switch-input';
import ReactAudioPlayer from 'react-audio-player';
import api from 'services/api';

import './style.css';


function CloudWords(props) {


   const { questions, results } = props;

   const [question, setQuestion] = useState('')
   const [options, setOptions] = useState([])
   const [labelOption, setLabelOption] = useState('')
   const [correctOption, setCorrectOption] = useState(false)
   const [load, setLoad] = useState(false)
   const [time, setTime] = useState()



   function addOptions(opt) {

      if (!opt.label) return;
      setOptions([...options, opt])
      setLabelOption('')
      setCorrectOption(false)
   }


   const removeOption = (o) => {
      options.splice(options.indexOf(o), 1)
      var opts = options;
      setOptions([...opts])
   }

   useEffect(() => {
      const setValuesExercise = () => {
         let response = options.filter(item => item.correct)
         questions({ question, options, time })
         results({ response: response })
      }
      setValuesExercise();
   }, [question, options, questions, results, time])



   const audioGenerate = async () => {
      setLoad(true);

      const result = correctOption ? await api.post('admin/ondemand/google-api/text-to-speech', { text: labelOption }) : ''

      addOptions({ label: labelOption, correct: correctOption, audio: correctOption ? result.filename : null })
      setLoad(false);
   }

   return (
      <div className="container cardFull">
         <h3>Exercício - Núvem de Palavras</h3>
         <div className='camposH'>
            <Fieldset label="Pergunta:" type="text" placeholder="Digite uma pergunta para o exercício." handleValue={question} handleOnChange={(e) => setQuestion(e.target.value)} />
            <Fieldset label="Tempo do exercício:" type="text" placeholder="Informe o tempo em segundos. Ex. 60" handleValue={time} handleOnChange={(e) => setTime(e.target.value)} />
         </div>

         <div>
            <div className="camposH">
               <Fieldset label="Palavra:" type="text" placeholder="Escreva uma palavra" handleValue={labelOption} handleOnChange={(e) => setLabelOption(e.target.value)} />

               <div>
                  <label>Correta?</label> <Switch name={'correct'} checked={correctOption} onChange={(e) => setCorrectOption(e.target.checked)} />
               </div>
            </div>
            <ButtonInlineSmall label="Adicionar" handleOnClick={() => audioGenerate()} isLoading={load} />
         </div>
         <br />

         {
            options.length > 0 &&
            <div className="areaOptions">
               <label>Opções Adicionadas:</label>
               <ul>
                  {
                     options.map((o, index) => (
                        <li key={index}>
                           <span>
                              <b>{o.label}</b>
                              {o.correct && <i className='bx bx-check-circle iconCorrect' ></i>}
                           </span>

                           <span>
                              {o.audio && <ReactAudioPlayer
                                 src={`${api.baseUrl.replace('pt', '')}google/audios/${o.audio}`}
                                 autoPlay={false}
                                 controls
                              />}

                              <i className='bx bx-trash iconRemove' onClick={() => removeOption(o)} style={{ marginLeft: 20 }}></i>
                           </span>
                        </li>
                     ))
                  }
               </ul>
            </div>
         }




      </div>
   );
}

export default CloudWords;