import React from 'react';
import styles from './style.module.css';
import { WithContext as ReactTags } from 'react-tag-input';

const InputTags = (props) => {
  const { label, handleDelete, handleAddition, tags } = props;

  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  return (
    <div className={styles.fieldset}>
      <label>{label}</label>

      <ReactTags
        tags={tags}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        handleDrag={() => { }}
        classNames={{

          tagInput: 'tagInputClass',
          tagInputField: styles.campos,
          selected: 'selectedClass',
          tag: styles.tag,
          remove: styles.remove,
        }}

      />

    </div>
  );
}

export default InputTags;