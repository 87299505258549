import React, { useState, useEffect, useRef } from 'react';
import ImageUploading from 'react-images-uploading';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import Avatar from 'components/Avatar';
import { useHistory } from "react-router-dom";
import { TrueOrFalse, CompleteSentence, MultipleChoice, Pronounce, Understanding, Information } from "components/Exercises";
import CKEditor from 'ckeditor4-react';

import './style.css';
import CloudWords from 'components/Exercises/CloudWords';
import Match from 'components/Exercises/Match';
import Dialog from 'components/Exercises/Dialog';
import ClickImage from 'components/Exercises/ClickImage';
import InputSelectSearch from 'components/InputSelectSearch';

export default function AddExercise() {
    const history = useHistory()
    const fileAudio = useRef()
    const fileVideo = useRef()

    const [title, setTitle] = useState('');
    const [tip, setTip] = useState('');
    const [points, setPoints] = useState(1);
    const [exerciseType, setExerciseType] = useState('');
    const [questions, setQuestions] = useState({});
    const [results, setResults] = useState({});
    const [course, setCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const [image, setImage] = useState('');
    const [type, setType] = useState('');
    const [youtubeCode, setYoutubeCode] = useState('');
    const [description, setDescription] = useState('');

    const [audioFile, setAudioFile] = useState('')
    const [videoFile, setVideoFile] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });



    const addExercise = async () => {



        setIsLoading(true);

        let audio = fileAudio.current.files[0];
        let video = fileVideo.current.files[0];

        const form = new FormData();
        form.append('title', title)
        form.append('tip', tip)
        form.append('points', points)
        form.append('exerciseType', exerciseType)
        form.append('questions', JSON.stringify(questions))
        form.append('results', JSON.stringify(results))
        form.append('course', course)
        form.append('image', image)
        form.append('audio', audio)
        form.append('video', video)
        form.append('exerciseType', type)
        form.append('youtubeCode', youtubeCode)
        form.append('description', description)

        try {

            await api({
                method: 'post',
                url: '/admin/ondemand/exercises',
                data: form,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                },
            })

            setIsLoading(false);
            setMsg({ message: 'Exercício adicionado com sucesso.', type: 'success' });
            setTitle('');
            setTip('');
            setPoints(1);
            setExerciseType('');
            setQuestions({});
            setResults({});
            setCourse('');
            setImage('');
            setVideoFile('')
            setAudioFile('')
            setYoutubeCode('')
            setDescription('')

            history.goBack();

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    useEffect(() => {
        const load = async () => {
            const response = await api.get(`admin/ondemand/courses?limit=1000`);
            const coursesData = response.data.map((item) => {
                return {
                    value: item._id,
                    label: item.name
                }
            })
            setCourses(coursesData)
        }
        load();

    }, []);


    return (
        <Page title="ondemand">
            <h1>
                Adicionar Exercício
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="areaInfo">

                <div className="cardFull">
                    <div className="container  cardFull">
                        <h3>Informações</h3>

                        <div className="camposH">

                            <InputSelectSearch id="curso" label="Curso" options={courses} handleValue={courses.find((item) => item.value === course)} handleOnChange={(item) => setCourse(item.value)} placeholder="Selecione um curso" />

                            <Fieldset label="Título" type="text" placeholder="Digite um título para o exercício." handleValue={title} handleOnChange={(e) => setTitle(e.target.value)} />
                            <Fieldset label="Pontos" type="number" min="1" placeholder="Informe uma pontuação" handleValue={points} handleOnChange={(e) => setPoints(e.target.value)} />
                        </div>


                        <label>Dica</label>
                        <CKEditor data={tip} onChange={(e) => {
                            setTip(e.editor.getData());
                        }} config={{
                            height: 170
                        }} />

                        <br />

                        <label>Explicação</label>
                        <CKEditor data={description} onChange={(e) => {
                            setDescription(e.editor.getData());
                        }} config={{
                            height: 170
                        }} />

                        <br />

                        <Fieldset label="Youtube Code" type="text" placeholder="Adicione o código do vídeo do youtube" handleValue={youtubeCode} handleOnChange={(e) => setYoutubeCode(e.target.value)} />
                    </div>

                    <div className="container">
                        <h3>Mídias</h3>

                        <div className="cardMidias">
                            <ImageUploading
                                acceptType={['jpg', 'jpeg', 'gif', 'png']}
                                resolutionType="ratio"
                                multiple={false}
                                onChange={(image) => {
                                    setImage(image[0].data_url);
                                }}
                                dataURLKey="data_url"
                            >
                                {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                    <div className="itemCard" onClick={onImageUpload}>
                                        <Avatar image={image} size={54} handleClass={'iconCard'} icon="image" />
                                        <small>Adicionar Imagem</small>
                                    </div>
                                )}
                            </ImageUploading>



                            <div className="itemCard" onClick={() => fileAudio.current.click()}>
                                <Avatar size={54} handleClass={'iconCard'} icon="play-circle" />
                                <small>{audioFile || 'Adicionar Audio'}</small>
                            </div>

                            <input type="file" ref={fileAudio} style={{ display: 'none' }} onChange={(e) => setAudioFile(e.target.files[0].name)} />

                            <div className="itemCard" onClick={() => fileVideo.current.click()}>
                                <Avatar size={54} handleClass={'iconCard'} icon="video-camera" />
                                <small>{videoFile || 'Adicionar Video'}</small></div>
                            <input type="file" ref={fileVideo} style={{ display: 'none' }} onChange={(e) => setVideoFile(e.target.files[0].name)} />

                        </div>


                    </div>


                    <h3>Selecione o tipo de exercicio</h3>

                    <div className="cardsOptions">

                        <div className={`cardOption ${type === 'verdadeiro_falso' ? 'cardActive' : ''}`} onClick={() => setType('verdadeiro_falso')}>
                            <i className='bx bx-check'></i>
                            <h4>Verdadeiro ou Falso</h4>
                        </div>

                        <div className={`cardOption ${type === 'complete_frase' ? 'cardActive' : ''}`} onClick={() => setType('complete_frase')}>
                            <i className='bx bx-message-square-edit'></i>
                            <h4>Complete a Frase</h4>
                        </div>


                        <div className={`cardOption ${type === 'multipla_escolha' ? 'cardActive' : ''}`} onClick={() => setType('multipla_escolha')}>
                            <i className='bx bx-check-circle' ></i>
                            <h4>Multipla Escolha</h4>
                        </div>

                        <div className={`cardOption ${type === 'pronuncia' ? 'cardActive' : ''}`} onClick={() => setType('pronuncia')}>
                            <i className='bx bx-microphone'></i>
                            <h4>Pronúncia</h4>
                        </div>

                        <div className={`cardOption ${type === 'compreensao' ? 'cardActive' : ''}`} onClick={() => setType('compreensao')}>
                            <i className='bx bx-disc'></i>
                            <h4>Compreensão</h4>
                        </div>

                        <div className={`cardOption ${type === 'informativo' ? 'cardActive' : ''}`} onClick={() => setType('informativo')}>
                            <i className='bx bx-info-circle'></i>
                            <h4>Informativo</h4>
                        </div>

                        <div className={`cardOption ${type === 'cloud' ? 'cardActive' : ''}`} onClick={() => setType('cloud')}>
                            <i className='bx bx-cloud-snow'></i>
                            <h4>Nuvem de Palavras</h4>
                        </div>

                        <div className={`cardOption ${type === 'match' ? 'cardActive' : ''}`} onClick={() => setType('match')}>
                            <i className='bx bx-copy-alt'></i>
                            <h4>Combine os Pares</h4>
                        </div>

                        <div className={`cardOption ${type === 'dialog' ? 'cardActive' : ''}`} onClick={() => setType('dialog')}>
                            <i className='bx bx-chat'></i>
                            <h4>Diálogo</h4>
                        </div>

                        <div className={`cardOption ${type === 'clickImage' ? 'cardActive' : ''}`} onClick={() => setType('clickImage')}>
                            <i className='bx bx-image'></i>
                            <h4>Imagem Click</h4>
                        </div>


                    </div>


                    {
                        type === 'verdadeiro_falso' &&
                        <div className="animated fadeIn">
                            <TrueOrFalse questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'complete_frase' &&
                        <div className="animated fadeIn">
                            <CompleteSentence questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'multipla_escolha' &&
                        <div className="animated fadeIn">
                            <MultipleChoice questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'pronuncia' &&
                        <div className="animated fadeIn">
                            <Pronounce questions={setQuestions} result={setResults} />
                        </div>
                    }

                    {
                        type === 'compreensao' &&
                        <div className="animated fadeIn">
                            <Understanding questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'informativo' &&
                        <div className="animated fadeIn">
                            <Information questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'cloud' &&
                        <div className="animated fadeIn">
                            <CloudWords questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'match' &&
                        <div className="animated fadeIn">
                            <Match questions={setQuestions} results={setResults} />
                        </div>
                    }

                    {
                        type === 'dialog' &&
                        <div className="animated fadeIn">
                            <Dialog questions={setQuestions} results={setResults} />
                        </div>
                    }


                    {
                        type === 'clickImage' &&
                        <div className="animated fadeIn">
                            <ClickImage questions={setQuestions} results={setResults} />
                        </div>
                    }


                </div>



            </div>


            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar" isLoading={isLoading} handleOnClick={addExercise} handleType="submit" />
            </div>

        </Page >
    )
}
