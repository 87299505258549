import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from "react-router-dom";

import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import FieldSelect from 'components/FieldSelect';
import Button from 'components/Button';

import api from 'services/api';


import './style.css';

export default function EditAudiobook() {

    const history = useHistory();
    const {_id} = useParams();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({message: '', type: ''});


    useEffect(() => {
        const loadAudiobook = async () => {
            const response = await api.get(`/admin/audiobooks/${_id}`);
            const audiobook = response.data;
            setName(audiobook.name);
            setDescription(audiobook.description);
            setColor(audiobook.color);
        }
        loadAudiobook();
    
        }, [_id])

    

    const editAudiobook = async () => {

        setIsLoading(true);

        try{
            await api.put('/admin/audiobooks', {_id, name, description, color});
            setIsLoading(false);
            setMsg({message:'Audiobooks alterado com sucesso.', type: 'success'});
        }catch(error){
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({message:msg, type: 'error'});
                return;
              }
            setMsg({message: 'Falha de conexão.', type: 'error'});
        }
    }


    const removeAudiobook = async () => {
        var response = window.confirm("Tem certeza que quer remover esse audiobook?");
        if(response === true){
            await api.delete(`/admin/audiobooks/${_id}`);
            history.goBack();
        }
    }


    return (
        <Page name="assessment-types">
            <h1>
                Editar Audiobook
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={()=> history.goBack() } />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={()=> removeAudiobook() } />
            </h1>


            <div className="informacoes">


                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o audiobooko." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                    <Fieldset label="Descrição" type="text" placeholder="Digite uma descrição para o audiobooko." multiline={true} handleValue={description} handleOnChange={(e) => setDescription(e.target.value)} />
                    <FieldSelect label="Cor" handleValue={color} handleOnChange={(e) => setColor(e.target.value)}>
                        <option value="">Selecione</option>
                        <option value="blue">Azul</option>
                        <option value="green">Verde</option>
                        <option value="red">Vermelho</option>
                        <option value="yellow">Amarelo</option>
                        <option value="purple">Roxo</option>
                        <option value="pink">Rosa</option>                      
                    </FieldSelect>
                </div>

            </div>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}
            
            <div className="areaBtn">
                <Button label="Editar Audiobook" isLoading={isLoading} handleOnClick={editAudiobook} handleType="submit" />
            </div>



        </Page>
    )
}
