import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import Pagination from "react-js-pagination";
import './style.css';

export default function Mailings() {

    const [mailings, setMailings] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);

    useEffect(() => {
        const loadMailings = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/mailings?page=${page.toString()}`);
                setMailings(response.data);
                setIsLoading(false);
                setPaginate(response.paginate);
            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os mailings.', type: 'error'});
            }
        }
        loadMailings();
    }, [page])

    return (
        <Page name="mailings" loading={isLoading}>
            <h1>Mailings</h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="40">Avatar</th>
                            <th>Usuário</th>
                            <th>Email</th>
                            <th>Interesse</th>
                            <th width="130">Data</th>
                            <th width="120">Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            mailings.map((mailing, index) => (
                                <tr key={index}>
                                    <td><Avatar name={mailing.user?.name} image={mailing.user?.avatar} size={28} /></td>
                                    <td>{mailing?.user?.name}</td>
                                    <td>{mailing?.user?.email}</td>
                                    <td>{mailing?.interest}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(mailing.createdAt), 'dd/M/Y')}</td>
                                    <td><i className='bx bx-time'></i>{format(parseISO(mailing.createdAt), 'HH:mm')}hrs</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={Number(paginate.limit)}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }}
            />


        </Page>
    )
}
