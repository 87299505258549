import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory, useParams } from "react-router-dom";

import './style.css';
import { Switch } from 'react-switch-input';
import BtnInline from 'components/BtnInline';

export default function EditFormQuestion() {

    const history = useHistory();
    const { _id } = useParams();


    const [question, setQuestion] = useState('');
    const [points, setPoints] = useState(1);
    const [responses, setResponses] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });

    const [resp, setResp] = useState('')
    const [correct, setCorrect] = useState(false)



    useEffect(() => {
        const load = async () => {
            const response = await api.get(`/admin/ondemand/form-questions/${_id}`);
            const result = response.data;
            setQuestion(result.question)
            setPoints(result.points)
            setResponses(result.responses)
        }
        load();

    }, [_id])



    const editFormQuestion = async () => {

        setIsLoading(true);

        try {
            await api.put('/admin/ondemand/form-questions', { _id, question, points, responses });
            setIsLoading(false);
            setMsg({ message: 'Pergunta de nivelamento altera com sucesso.', type: 'success' });

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexãitem.', type: 'error' });
        }
    }

    const removeOption = (item) => {
        responses.splice(responses.indexOf(item), 1)
        var opts = responses;
        setResponses([...opts])
    }

    const addResponse = () => {
        var resps = [...responses]
        resps.push({
            response: resp,
            correct
        })
        setResponses(resps)
        setResp('')
        setCorrect(false)
    }


    const removeFormQuestion = async () => {
        var response = window.confirm("Tem certeza que quer remover essa pergunta?");
        if (response === true) {
            await api.delete(`/admin/ondemand/form-questions/${_id}`);
            history.goBack();
        }
    }

    return (
        <Page name="ondemand">
            <h1>
                Editar Pergunta
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
                <BtnTitle label="Remover" iconName="trash" iconColor="#e63946" handleOnClick={() => removeFormQuestion()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <div className='camposH'>
                        <Fieldset label="Pergunta" type="text" placeholder="Digite a pergunta de nivelamento" handleValue={question} handleOnChange={(e) => setQuestion(e.target.value)} />
                        <Fieldset label="Pontos" type="number" placeholder="1" handleValue={points} handleOnChange={(e) => setPoints(e.target.value)} />
                    </div>

                    <h3>Respostas</h3>
                    <div className='camposH'>
                        <Fieldset label="Resposta" type="text" placeholder="Digite uma resposta" handleValue={resp} handleOnChange={(e) => setResp(e.target.value)} />
                        <div>
                            <label>Correta?</label> <Switch name={'correct'} checked={correct} onChange={(e) => setCorrect(e.target.checked)} />
                        </div>
                    </div>
                    <BtnInline label="Adicionar" handleOnClick={() => addResponse()} />
                    {
                        responses.length > 0 &&
                        <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                            <thead>
                                <tr>
                                    <th>Resposta</th>
                                    <th width="30">Correta?</th>
                                    <th width="30">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    responses.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.response}</td>

                                            <td>
                                                <span>{item.correct ? <i className='bx bx-check-circle iconCorrect' ></i> : '-'}</span>
                                            </td>
                                            <td>
                                                <i className='bx bx-trash iconRemove' onClick={() => removeOption(item)}></i>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }




                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Salvar" isLoading={isLoading} handleOnClick={editFormQuestion} handleType="submit" />
            </div>

        </Page>
    )
}
