import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function Categories() {
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const loadCategories = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/categories`);
                setCategories(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadCategories();
    }, []);

    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Categorias
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/categories/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="200">Nome</th>
                            <th>Descrição</th>
                            <th width="130">Criado em:</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            categories.map((category, index) => (
                                <tr key={index}>
                                    <td><Link to={`/ondemand/categories/edit/${category._id}`}>{category.name}</Link></td>
                                    <td>{category.description}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(category.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Link to={`/ondemand/categories/edit/${category._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Categoria" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


        </Page>
    )
}
