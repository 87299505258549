import React, { useState, useEffect, useRef } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import Pagination from "react-js-pagination";
import InputSearch from 'components/InputSearch';

import './styles.css';
import FieldSelect from 'components/FieldSelect';
import UIkit from 'uikit';
import 'uikit/dist/css/uikit.min.css';


export default function Courses() {

    const history = useHistory();
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('')
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);

    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [level, setLevel] = useState('');
    const [levels, setLevels] = useState([]);

    const listRef = useRef(null);



    useEffect(() => {

        const loadCourses = async () => {

            if (search.length === 0) setIsLoading(true);

            try {

                const response = await api.get(`/admin/ondemand/courses`, { params: { page, search, category, level } });
                setCourses(response.data);
                setPaginate(response.paginate);
                setIsLoading(false);

            } catch (error) {

                setIsLoading(false);

            }
        }

        loadCourses();

    }, [page, search, category, level]);


    useEffect(() => {
        const loadCategories = async () => {
            const response = await api.get(`admin/ondemand/categories`);
            setCategories(response.data);
        }
        loadCategories();

        const loadLevels = async () => {
            const response = await api.get(`admin/ondemand/levels`);
            setLevels(response.data);
        }
        loadLevels();


    }, []);








    const remove = async (item) => {
        var response = window.confirm(`Atenção ao remover o curso "${item.name}", todos os exercícios relacionados a ele serão removidos. Você tem certeza?`);
        if (response === true) {
            await api.delete(`/admin/ondemand/courses/${item._id}`);
            setPage(1)
        }
    }



    useEffect(() => {

        const changeOrder = async (courseId, order) => {
            const response = await api.put(`/admin/ondemand/courses/${courseId}/reorder`, { order })
            console.log(response)
        }

        const sortable = UIkit.sortable(listRef.current);
        let currentOrder = []
        let newOrder = []

        const onStart = (e) => {
            currentOrder = [...e.target.childNodes]

        };

        const onMoved = (e) => {
            newOrder = [...e.target.childNodes]

        }

        const onEnd = (e) => {
            const atual = currentOrder.map((item) => item.dataset)
            const novo = newOrder.map((item) => item.dataset)

            for (let i = 0; i < atual.length; i++) {
                if (atual[i].id !== novo[i].id) {
                    changeOrder(novo[i].id, atual[i].order)
                }
            }
        };

        UIkit.util.on(sortable.$el, 'start', onStart);
        UIkit.util.on(sortable.$el, 'moved', onMoved);
        UIkit.util.on(sortable.$el, 'stop', onEnd);

        return () => {
            sortable.$destroy();
            UIkit.util.off(sortable.$el, 'start', onStart);
        };

    }, [isLoading]);


    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Cursos
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/courses/add')} />
            </h1>

            <div className='areaFilters'>
                <InputSearch placeholder="Buscar curso..." handleValue={search} handleOnChange={(e) => setSearch(e.target.value)} />
                <FieldSelect label="Categoria" handleValue={category} handleOnChange={(e) => setCategory(e.target.value)}>
                    <option value="">Selecione</option>
                    {
                        categories.map((item, index) => (
                            <option key={index} value={item._id}>{item.name}</option>
                        ))
                    }
                </FieldSelect>
                <FieldSelect label="Nível" handleValue={level} handleOnChange={(e) => setLevel(e.target.value)}>
                    <option value="">Selecione</option>
                    {
                        levels.map((item, index) => (
                            <option key={index} value={item._id}>{item.name}</option>
                        ))
                    }
                </FieldSelect>

            </div>

            <div className="container">

                <div ref={listRef} >
                    {
                        courses.map((item, index) => (
                            <div key={index} className='itemTable' data-id={item._id} data-order={item.order} data-title={item.name} data-category={item.category}>
                                <div><i uk-tooltip="Arraste para ordernar" className={`bx bx-menu`}></i></div>
                                <div className='infoItem'>
                                    <div className='thumb' style={{ backgroundImage: `url(${item.image})` }}></div>

                                    <div className='title'>
                                        <Link to={`/ondemand/courses/edit/${item._id}`}>{item.name} <br /><small>{item?.category?.name}</small></Link>
                                    </div>

                                </div>

                                <div className='areaIcons'>
                                    <div><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/M/Y')}</div>
                                    <div align="center"><Link to={`/ondemand/courses/edit/${item._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Curso" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></div>
                                    <div align="center"><span onClick={() => remove(item)} className="iconRemove"><i className='bx bx-trash' uk-tooltip="Remover Curso" ></i></span></div>
                                </div>
                            </div>
                        ))
                    }
                </div>



            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={Number(paginate.limit)}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }} />


        </Page >
    )
}
