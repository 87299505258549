import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function PagseguroPlans() {
    const history = useHistory();

    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const loadPlans = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/plans`);
                setPlans(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadPlans();
    }, []);

    return (
        <Page name="sales" loading={isLoading}>
            <h1>Planos - Pagseguro
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/plans/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="200">Nome</th>
                            <th>Valor</th>
                            <th>Code Pagseguro</th>
                            <th>Trial</th>
                            <th>Período</th>
                            <th width="130">Criado em:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            plans.map((plan, index) => {

                                let valor = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(plan.amountPerPayment)

                                return <tr key={index}>
                                    <td>{plan.name}</td>
                                    <td>{valor}</td>
                                    <td>{plan.code}</td>
                                    <td>{plan.trialPeriodDuration ? `${plan.trialPeriodDuration} dias` : '-'}</td>
                                    <td>{plan.period}</td>
                                    <td><i className='bx bx-calendar'></i>{plan?.createdAt && format(parseISO(plan.createdAt), 'dd/M/Y')}</td>
                                </tr>
                            })
                        }

                    </tbody>
                </table>

            </div>


        </Page>
    )
}
