import React, { useState } from 'react';

import Page from 'components/Templates/Page';

import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import { useHistory } from "react-router-dom";
import api from 'services/api';

import './style.css';

export default function MyAccount() {
    const history = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });


    const addClient = async (e) => {
        e.preventDefault();

        if (!validate() && !isLoading) { return };

        setIsLoading(true);

        try {
            await api.post('/admin/clients', { name, email, password });
            setIsLoading(false);
            setMsg({ message: 'Cliente Adicionado com sucesso.', type: 'success' });
            setName('')
            setEmail('')
            setPassword('')
            setVerifyPassword('')

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }

    function validate() {
        if (!name) {
            setMsg({ message: 'Preencha o campo nome', type: 'warning' });
            return false;
        }

        if (!email) {
            setMsg({ message: 'Preencha o campo e-mail', type: 'warning' });
            return false;
        }

        if (!password) {
            setMsg({ message: 'Digite uma senha.', type: 'warning' });
            return false;
        }

        if (password.length < 6) {
            setMsg({ message: 'Sua senha deve ter no mínimo 6 caracteres.', type: 'warning' });
            return false;
        }

        if (!verifyPassword) {
            setMsg({ message: 'Confirme sua senha', type: 'warning' });
            return false;
        }

        if (verifyPassword !== password) {
            setMsg({ message: 'As senhas digitadas não conferem.', type: 'warning' });
            return false;
        }
        return true;
    }

    return (
        <Page name="my-account">
            <h1>Adicionar Cliente
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>
            <div className="areaInfo">

                <form onSubmit={addClient} className="areaInformacoes">
                    <div className="informacoes">

                        <div className="container">
                            <h3>Informações Básicas</h3>
                            <Fieldset label="Nome" type="text" placeholder="Informe o seu nome" autofocus={true} handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                            <Fieldset label="E-mail" type="text" placeholder="Informe o seu nome" handleValue={email} handleOnChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="container">
                            <h3>Segurança</h3>
                            <Fieldset label="Senha" type="password" placeholder="Digite uma senha (min. 6 caracteres)" handleValue={password} handleOnChange={(e) => setPassword(e.target.value)} />
                            <Fieldset label="Confirmar Senha" type="password" placeholder="Confirme sua nova senha" handleValue={verifyPassword} handleOnChange={(e) => setVerifyPassword(e.target.value)} />
                        </div>

                        {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

                        <div className="areaBtn">
                            <Button label="Adicionar" isLoading={isLoading} handleOnClick={addClient} handleType="submit" />
                        </div>



                    </div>
                </form>




            </div>




        </Page>
    )
}
