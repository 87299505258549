import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';

export default function Levels() {
    const history = useHistory();
    const [levels, setLevels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const loadLevels = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/levels`);
                setLevels(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadLevels();
    }, []);

    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Níveis
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/levels/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="70">Medalha</th>
                            <th width="200">Nome</th>
                            <th>Descrição</th>
                            <th>Pontuação</th>
                            <th width="130">Criado em:</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            levels.map((level, index) => (
                                <tr key={index}>
                                    <td><img src={level.image} alt={level.name} /></td>
                                    <td><Link to={`/ondemand/levels/edit/${level._id}`}>{level.name}</Link></td>
                                    <td>{level.description}</td>
                                    <td>{level.points}</td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(level.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Link to={`/ondemand/levels/edit/${level._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Nível" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


        </Page>
    )
}
