import React, { useState, useEffect } from 'react';
import Switch from 'react-input-switch';
import CKEditor from 'ckeditor4-react';

function TrueOrFalse(props) {

   const { questions, results, infoExercise } = props;
   const [question, setQuestion] = useState('');
   const [value, setValue] = useState(false);


   useEffect(() => {
      questions({ question })
      results({ response: value })

   }, [questions, results, question, value])


   useEffect(() => {

      if (infoExercise) {

      }

   }, [infoExercise])

   return (
      <div className="container cardFull">
         <h3>Exercício Verdadeiro ou Falso</h3>
         {/* <Fieldset label="Pergunta" type="text" placeholder="Digite uma pergunta" handleValue={question} handleOnChange={(e) => setQuestion(e.target.value)} /> */}

         <CKEditor data={question} onChange={(e) => {
            setQuestion(e.editor.getData());
         }} config={{
            height: 120
         }} />

         <small style={{ display: 'inline-block', marginBottom: 20 }}>Resposta: <b>{value ? 'Verdadeiro' : 'Falso'}</b></small><br />
         <Switch on={true} off={false} value={value} onChange={setValue} />
      </div>
   );
}

export default TrueOrFalse;