import React, { useState, useEffect, useRef } from 'react';

import Page from 'components/Templates/Page';
import InputSearch from 'components/InputSearch';

import api from 'services/api';
import { Link, useHistory } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import { parseISO, format } from 'date-fns';
import Pagination from "react-js-pagination";
import InputSelectSearch from 'components/InputSelectSearch';
import './styles.css';
import UIkit from 'uikit';
import 'uikit/dist/css/uikit.min.css';

import Msg from 'components/Msg';
import Badge from 'components/Badge';
import getTypeExercise from 'utils/getTypeExercise';

export default function Exercises() {



    const history = useHistory();
    const [exercises, setExercises] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [paginate, setPaginate] = useState({});
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('')
    const [course, setCourse] = useState(null)
    const [courses, setCourses] = useState([])
    const [msg, setmsg] = useState(null)
    const listRef = useRef(null);


    useEffect(() => {

        const loadExercises = async () => {

            if (search.length === 0) setIsLoading(true);

            try {
                let response;
                if (search.length > 0) {
                    response = await api.get(`/admin/ondemand/exercises?page=${page.toString()}&search=${search}`);
                } else {
                    response = await api.get(`/admin/ondemand/exercises?page=${page.toString()}`);
                }
                setExercises(response.data);
                setPaginate(response.paginate);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }

        loadExercises();
    }, [page, search]);

    // Load courses
    useEffect(() => {
        const load = async () => {
            const response = await api.get(`admin/ondemand/courses?limit=1000`);
            const coursesData = response.data.map((item) => {
                return {
                    value: item._id,
                    label: item.name
                }
            })
            setCourses(coursesData)
        }
        load();

    }, []);

    // Fiter by course
    useEffect(() => {

        if (!course) return;

        const filter = async () => {

            if (search.length === 0) setIsLoading(true);

            try {
                let response = await api.get(`/admin/ondemand/exercises?course=${course}`);
                setExercises(response.data);
                setPaginate(response.paginate);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }

        filter();
    }, [course, search]);

    const remove = async (item) => {
        var response = window.confirm(`Você quer mesmo remover o exercício "${item.title}"?`);
        if (response === true) {
            const result = await api.delete(`/admin/ondemand/exercises/${item._id}`);
            if (result.success) {
                setmsg({ type: 'success', message: `O exercício '${item.title}' foi removido com sucesso.` })
                const currentExercises = [...exercises]
                currentExercises.splice(currentExercises.indexOf(item), 1)
                setExercises(currentExercises)
            } else {
                setmsg({ type: 'error', message: `Falha ao remover o exercício '${item.title}'.` })
            }
        }
    }



    useEffect(() => {

        const changeOrder = async (exerciseId, order) => {
            const response = await api.put(`/admin/ondemand/exercises/${exerciseId}/reorder`, { order })
            console.log(response)
        }

        const sortable = UIkit.sortable(listRef.current);
        let currentOrder = []
        let newOrder = []

        const onStart = (e) => {
            currentOrder = [...e.target.childNodes]

        };

        const onMoved = (e) => {
            newOrder = [...e.target.childNodes]

        }

        const onEnd = (e) => {
            const atual = currentOrder.map((item) => item.dataset)
            const novo = newOrder.map((item) => item.dataset)

            for (let i = 0; i < atual.length; i++) {
                if (atual[i].id !== novo[i].id) {
                    changeOrder(novo[i].id, atual[i].order)
                }
            }
        };

        UIkit.util.on(sortable.$el, 'start', onStart);
        UIkit.util.on(sortable.$el, 'moved', onMoved);
        UIkit.util.on(sortable.$el, 'stop', onEnd);

        return () => {
            sortable.$destroy();
            UIkit.util.off(sortable.$el, 'start', onStart);
        };

    }, [isLoading]);


    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Exercícios
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/exercises/add')} />
            </h1>
            <div className="camposH">

                <InputSelectSearch id="curso" options={courses} handleValue={courses.find((item) => item.value === course)} handleOnChange={(item) => setCourse(item.value)} placeholder="Selecione um curso" />
                <InputSearch placeholder="Buscar exercício..." handleValue={search} handleOnChange={(e) => setSearch(e.target.value)} />

            </div>
            {
                msg && <Msg type={msg.type} message={msg.message} />
            }
            <div className="container">



                <div ref={listRef}>

                    {
                        exercises.map((item, index) => (
                            <div key={index} className='itemTable' data-id={item._id} data-order={item.order} data-title={item.title}>

                                <i uk-tooltip="Arraste para ordernar" className={`bx bx-menu`}></i>
                                <div style={{ width: '100%' }}>
                                    <Link to={`/ondemand/exercises/edit/${item._id}`}>{item.title}</Link><br />
                                    <small>{getTypeExercise(item.exerciseType)}</small>
                                </div>
                                <div style={{ width: '100%' }}>
                                    {item.course.name}
                                </div>
                                <Badge label={item.points} />

                                <span style={{ display: 'flex', gap: 7, alignItems: 'center' }}><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/M/Y')}</span>
                                <span onClick={() => remove(item)} className="iconRemove"><i className='bx bx-trash' uk-tooltip="Remover Excercício" ></i></span>
                                <Link to={`/ondemand/exercises/edit/${item._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Excercício" style={{ fontSize: '17px', display: 'flex' }} ></i></Link>

                            </div>
                        ))
                    }

                </div>


            </div>

            <Pagination
                innerClass="pagination"
                activePage={Number(paginate.page)}
                totalItemsCount={Number(paginate.total)}
                pageRangeDisplayed={Number(paginate.limit)}
                hideFirstLastPages={true}
                onChange={(page) => {
                    setPage(page);
                }} />


        </Page >
    )
}
