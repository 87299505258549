import React, { useState, useEffect } from 'react';
import Fieldset from 'components/Fieldset';
import gifRecording from 'assets/images/record.gif';
import api from 'services/api';
import ReactAudioPlayer from 'react-audio-player';

// import { Container } from './styles';

function Understanding(props) {
   const { questions, results, infoExercise } = props;
   const [sentence, setSentence] = useState('')
   const [audio, setAudio] = useState('')
   const [load, setLoad] = useState(false)

   const audioGenerate = async () => {
      setLoad(true);
      const result = await api.post('admin/ondemand/google-api/text-to-speech', { text: sentence })
      setLoad(false);
      setAudio(result.filename)
   }

   useEffect(() => {
      questions({ question: audio, audio })
      results({ response: sentence })
   }, [sentence, questions, results, audio])

   useEffect(() => {

      if (infoExercise) {

      }

   }, [infoExercise])


   return <div className="container">
      <h3>Exercício de Compreensão</h3>
      <div className="camposH camposStart">
         <Fieldset label="Digite uma frase ou palavra para gerar um audio." type="text" placeholder="Digite uma palavra ou frase" handleValue={sentence} handleOnChange={(e) => setSentence(e.target.value)} />
         <button onClick={() => audioGenerate()} className="btnRecording">
            <i className='bx bx-music'></i>
            Gerar audio
         </button>
      </div>
      {load ? <img src={gifRecording} width="50" alt="Carregando..." /> : ''}
      {
         audio &&
         <ReactAudioPlayer
            src={`${api.baseUrl.replace('pt', '')}google/audios/${audio}`}
            autoPlay
            controls />
      }
   </div>;
}

export default Understanding;