import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import Badge from 'components/Badge';

export default function Categories() {
    const history = useHistory();
    const [questions, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const load = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/form-questions`);
                setCategories(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        load();
    }, []);

    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Perguntas de Nivelamento
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/form-questions/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th>Pergunta</th>
                            <th width="100">Pontos</th>
                            <th width="130">Criado em:</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            questions.map((item, index) => (
                                <tr key={index}>
                                    <td><Link to={`/ondemand/form-questions/edit/${item._id}`}>{item.question}</Link></td>
                                    <td><Badge label={`${item.points} pts`} /></td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Link to={`/ondemand/form-questions/edit/${item._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Pergunta" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


        </Page>
    )
}
