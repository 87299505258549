import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import api from 'services/api';
import Avatar from 'components/Avatar';
import { parseISO, format } from 'date-fns';
import { Link } from "react-router-dom";
import './style.css';

export default function Dashboard() {

    const [dashboard, setDashboard] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const loadDasboard = async () => {
            try {
                const response = await api.get(`/admin/dashboards`);
                console.log(response)
                setDashboard(response);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                // setMsg({message: 'Falha ao listar os logs.', type: 'error'});
            }
        }
        loadDasboard();

    }, []);


    return (
        <Page name="dashboard" loading={isLoading}>
            <h1>Dashboard</h1>


            <div className="areaInfo">
                <div className="container cardDash">
                    <h3>Clientes</h3>
                    <h1>{dashboard.totalUsers}<br /><small>Total Cadastrados</small></h1>
                </div>
                <div className="container cardDash">
                    <h3>Cursos</h3>
                    <h1>{dashboard.totalCourses}<br /><small>Total Adicionados</small></h1>
                </div>
                <div className="container cardDash">
                    <h3>Logs</h3>
                    <h1>{dashboard.totalLogs}<br /><small>Total registrados</small></h1>
                </div>
            </div>



            <br />

            <div className="areaInfo">

                <div className="container cardDash">
                    <h3>Últimos Logs</h3>
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th width="20">#</th>
                                <th>Usuário</th>
                                <th>Descrição</th>
                                <th>Quando?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dashboard?.logs?.map((log, index) => {

                                    return (
                                        <tr key={index}>
                                            <td><Avatar name={log?.user?.name || '?'} image={log?.user?.avatar} size={28} /></td>
                                            <td><p>{log?.user?.name || 'Usuário não identificado'}</p></td>
                                            <td><p>{log.description}</p></td>
                                            <td><p><i className='bx bx-calendar'></i>{format(parseISO(log.createdAt), 'dd/M/Y')} às {format(parseISO(log.createdAt), 'HH:mm')}hrs</p></td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </div>

            <div className="areaInfo">


                <div className="container cardDash">
                    <h3>Últimos Clientes</h3>
                    <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                        <thead>
                            <tr>
                                <th width="20">#</th>
                                <th>Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dashboard?.users?.map((user, index) => (
                                    <tr key={index}>
                                        <td><Avatar name={user.name} image={user.avatar} size={28} /></td>
                                        <td><p><Link to={`/users/edit/${user._id}`}>{user.name}</Link><br /><small>{user.email}</small></p></td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>



            </div>




        </Page>
    )
}
