import React, { useState, useEffect } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import FieldSelect from 'components/FieldSelect';
import { useHistory } from "react-router-dom";

import './style.css';

export default function AddSaleProfile() {

    const history = useHistory();

        
    const [name, setname] = useState('')
    const [commission, setcommission] = useState('')
    const [totalSalesForAStart, settotalSalesForAStart] = useState('')
    const [numberStarsForNextLevel, setnumberStarsForNextLevel] = useState('')
    const [nextLevel, setnextLevel] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });


    const [saleProfiles, setSaleProfiles] = useState([]);



    
    useEffect(() => {
        if(name.length ===0 ){
            const loadSaleProfiles = async () => {
                const response = await api.get(`admin/ondemand/sale-profiles`);
                setSaleProfiles(response.data);
            }
            loadSaleProfiles();
        }
    }, [name]);



    const addSaleProfile = async () => {

        setIsLoading(true);

        try {
            await api.post('/admin/ondemand/sale-profiles', { 
                name,
                commission,
                totalSalesForAStart,
                numberStarsForNextLevel,
                nextLevel
             });
            setIsLoading(false);
            setMsg({ message: 'Perfil de venda adicionado com sucesso.', type: 'success' });
            
            setname('')
            setcommission('')
            settotalSalesForAStart('')
            setnumberStarsForNextLevel('')
            setnextLevel('')

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    return (
        <Page name="sales">
            <h1>
                Adicionar Perfil de Venda
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <Fieldset label="Nome" type="text" placeholder="Digite um nome para o perfil." handleValue={name} handleOnChange={(e) => setname(e.target.value)} />
                    <Fieldset label="Comissão(%)" type="number" placeholder="Digite o valor" handleValue={commission} handleOnChange={(e) => setcommission(e.target.value)} />
                    <Fieldset label="Qtd. de vendas para uma estrela" type="number" placeholder="Informe o número de vendas" handleValue={totalSalesForAStart} handleOnChange={(e) => settotalSalesForAStart(e.target.value)} />
                    <Fieldset label="Qtd. de estrelas para o próximo nível" type="number" placeholder="Digite numberStarsForNextLevel" handleValue={numberStarsForNextLevel} handleOnChange={(e) => setnumberStarsForNextLevel(e.target.value)} />
                    <FieldSelect label="Próximo Nível" handleValue={nextLevel} handleOnChange={(e) => setnextLevel(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            saleProfiles.map((item, index) => (
                                <option key={index} value={item._id}>{item.name}</option>
                            ))
                        }
                    </FieldSelect>
                    
                </div>

            </div>

            { msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Perfil" isLoading={isLoading} handleOnClick={addSaleProfile} handleType="submit" />
            </div>

        </Page>
    )
}
