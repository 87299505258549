import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import CKEditor from 'ckeditor4-react';
import Button from 'components/Button';
import FieldSelect from 'components/FieldSelect';

import api from 'services/api';

import './style.css';

export default function Settings() {

    const history = useHistory();
    const [termsOfUse, setTermsOfUse] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [aboutUs, setAboutUs] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState({ message: '', type: '' });
    const [planOndemand, setplanOndemand] = useState({})
    const [plans, setPlans] = useState([])

    useEffect(() => {

        const loadSetting = async () => {
            const response = await api.get(`/admin/settings/`);
            const settings = response.data;
            setTermsOfUse(settings.termsOfUse)
            setPrivacyPolicy(settings.privacyPolicy)
            setAboutUs(settings.aboutUs)
            setplanOndemand(settings.planOndemand?._id)
            setIsLoading(false)
        }

        loadSetting();


        const loadPlans = async () => {
            const response = await api.get(`/admin/ondemand/plans/`);
            setPlans(response.data)
        }

        loadPlans();

    }, [])

    const edit = async () => {


        const selectedPlanOndemand = plans.filter(plan => plan._id === planOndemand)[0]


        try {
            await api.put('/admin/settings', { termsOfUse, privacyPolicy, aboutUs, planOndemand: selectedPlanOndemand });
            setIsLoading(false);
            setMsg({ message: 'Dados alterados com sucesso.', type: 'success' });
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }

    return (
        <Page name="settings" loading={isLoading} >
            <h1>
                Configurações
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>

            <div className="informacoes">

                <div className="container">

                    <h3>Plano - Ondemand <br /></h3>
                    {/* <label>Plano que está ativo para assinaturas na plataforma ondemand</label> */}
                    <FieldSelect label="Plano" handleValue={planOndemand} handleOnChange={(e) => setplanOndemand(e.target.value)}>
                        <option value="">Selecione</option>
                        {
                            plans.map((item, index) => (
                                <option key={index} value={item._id}>{item.name}</option>
                            ))
                        }
                    </FieldSelect>

                    <h3>Termos de Uso</h3>
                    <CKEditor data={termsOfUse} onChange={(e) => {
                        setTermsOfUse(e.editor.getData());
                    }} config={{
                        height: 270
                    }} />

                    <h3>Políticas de Privacidade</h3>
                    <CKEditor data={privacyPolicy} onChange={(e) => {
                        setPrivacyPolicy(e.editor.getData());
                    }} config={{
                        height: 270
                    }} />

                    <h3>Sobre o Ondemand</h3>
                    <CKEditor data={aboutUs} onChange={(e) => {
                        setAboutUs(e.editor.getData());
                    }} config={{
                        height: 270
                    }} />

                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtn">
                <Button label="Editar" isLoading={isLoading} handleOnClick={edit} handleType="submit" />
            </div>

        </Page>
    )
}