import React, { useState, useEffect } from 'react';
import useSpeechToText from 'react-hook-speech-to-text';
import gifRecording from 'assets/images/record.gif';
import Fieldset from 'components/Fieldset';

import './style.css'

function Pronounce(props) {

   const { questions, result, infoExercise } = props
   const [sentence, setSentence] = useState('');

   const {
      error,
      isRecording,
      results,
      startSpeechToText,
      stopSpeechToText,

   } = useSpeechToText({
      continuous: false,
      timeout: 100000,
      crossBrowser: true,
      // speechRecognitionProperties: { interimResults: true },
      googleApiKey: "AIzaSyB2Bxt34PLhlb_tSUtN9GlnHMIATnsxVYE",
      useOnlyGoogleCloud: true,
      googleCloudRecognitionConfig: {
         enableAutomaticPunctuation: false,
         encoding: "LINEAR16",
         languageCode: "en-US",
         model: "default",
      }
   });


   useEffect(() => {

      if (results.length > 0) {
         let frase = results.pop()
         questions({ question: frase })
         result({ response: frase })
         setSentence(frase)
      }

   }, [results, questions, result, setSentence])


   useEffect(() => {

      if (infoExercise) {

      }

   }, [infoExercise])



   if (error) return <p>Web Speech API is not available in this browser</p>;




   return <div className="container">
      <h3>Exercício de Pronúncia</h3>

      <label>Click no botão abaixo para gravar a frase ou palavra que o aluno terá que repetir.</label><br></br>
      <div className="camposH">
         <button onClick={isRecording ? stopSpeechToText : startSpeechToText} className="btnRecording">
            <i className='bx bx-microphone'></i>
            {isRecording ? 'Parar gravação' : 'Iniciar gravação'}
         </button>
         {isRecording ? <img src={gifRecording} width="50" alt="Carregando..." /> : ''}
      </div>
      <br />

      <>
         <label>Frase reconhecida pelo Google.</label>
         <div className="areaFrase">
            <Fieldset type="text" placeholder="Diga uma alguma coisa o digite" handleValue={sentence} handleOnChange={(e) => setSentence(e.target.value)} />
         </div>
      </>




   </div>;
}

export default Pronounce;