import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import Page from 'components/Templates/Page';
import api from 'services/api';
import { useHistory, Link } from "react-router-dom";
import BtnTitle from 'components/BtnTitle';
import Badge from 'components/Badge';

export default function Forms() {
    const history = useHistory();
    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const loadForms = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/admin/ondemand/forms`);
                setForms(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        loadForms();
    }, []);

    return (
        <Page name="ondemand" loading={isLoading}>
            <h1>Formulários
                <BtnTitle label="Adicionar" iconName="plus-circle" handleOnClick={() => history.push('/ondemand/forms/add')} />
            </h1>

            <div className="container">
                <table className="uk-table uk-table-striped uk-table-small  uk-table-middle">
                    <thead>
                        <tr>
                            <th width="200">Nome</th>
                            <th>Descrição</th>
                            <th>Nº de Perguntas</th>
                            <th>Qtd de Pontos</th>
                            <th width="130">Criado em:</th>
                            <th width="20">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            forms.map((item, index) => (
                                <tr key={index}>
                                    <td><Link to={`/ondemand/forms/edit/${item._id}`}>{item.name}</Link></td>
                                    <td>{item.description}</td>
                                    <td>{item.questions.length}</td>
                                    <td><Badge label={item.points} /></td>
                                    <td><i className='bx bx-calendar'></i>{format(parseISO(item.createdAt), 'dd/M/Y')}</td>
                                    <td align="center"><Link to={`/ondemand/forms/edit/${item._id}`}><i className='bx bx-pencil' uk-tooltip="Editar Formulário" style={{ fontSize: '17px', display: 'flex' }} ></i></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


        </Page>
    )
}
