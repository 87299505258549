import React, { useState } from 'react';
import Page from 'components/Templates/Page';
import Msg from 'components/Msg';
import BtnTitle from 'components/BtnTitle';
import Fieldset from 'components/Fieldset';
import Button from 'components/Button';
import api from 'services/api';
import { useHistory } from "react-router-dom";
import FieldsetMoney from 'components/FieldsetMoney';
import FieldSelect from 'components/FieldSelect';

import './style.css';

export default function PagseguroAddPlan() {

    const history = useHistory();

    const [name, setName] = useState('');
    const [details, setDetails] = useState('');
    const [amountPerPayment, setAmountPerPayment] = useState(0)
    const [trialPeriodDuration, setTrialPeriodDuration] = useState('')
    const [period, setPeriod] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ message: '', type: '' });

    const addPlan = async () => {

        setIsLoading(true);

        try {

            await api.post('/admin/ondemand/plans', { name, amountPerPayment, trialPeriodDuration, details, period });
            setIsLoading(false);
            setMsg({ message: 'Plano adicionadado com sucesso.', type: 'success' });
            setName('');
            setDetails('');
            setAmountPerPayment(0);
            setTrialPeriodDuration('');
            setPeriod('');

        } catch (error) {
            setIsLoading(false);
            if (error.response?.data.msg) {
                const msg = error.response.data.msg;
                setMsg({ message: msg, type: 'error' });
                return;
            }
            setMsg({ message: 'Falha de conexão.', type: 'error' });
        }
    }


    return (
        <Page name="sales">
            <h1>
                Adicionar Plano
                <BtnTitle label="Voltar" iconName="chevron-left" handleOnClick={() => history.goBack()} />
            </h1>


            <div className="informacoes">

                <div className="container">
                    <h3>Informações</h3>
                    <div className="camposH">
                        <Fieldset label="Nome" type="text" placeholder="Digite um nome para plano." handleValue={name} handleOnChange={(e) => setName(e.target.value)} />
                        <FieldsetMoney label="Valor do plano" handleValue={amountPerPayment} handleOnChange={(e, value) => setAmountPerPayment(value)} />
                        <Fieldset label="Trial" type="number" placeholder="Nº de dias" handleValue={trialPeriodDuration} handleOnChange={(e) => setTrialPeriodDuration(e.target.value)} />
                        <FieldSelect label="Plano" handleValue={period} handleOnChange={(e) => setPeriod(e.target.value)}>
                            <option value="">Selecione</option>
                            <option value="MONTHLY">Mensal</option>
                            <option value="YEARLY">Anual</option>
                        </FieldSelect>
                    </div>

                    <Fieldset label="Detalhes" type="text" placeholder="Forneça algumas informações sobre o plano" multiline={true} handleValue={details} handleOnChange={(e) => setDetails(e.target.value)} />
                </div>

            </div>

            {msg.message ? <Msg type={msg.type} message={msg.message} /> : null}

            <div className="areaBtnPlan">
                <Button label="Adicionar Plano" isLoading={isLoading} handleOnClick={addPlan} handleType="submit" />
            </div>

        </Page>
    )
}
